



export function msToDurationString( { value, includeSuffix = true } = {} ) {

	//

	return sToDurationString( { value: parseInt( value / 1000, 10 ), includeSuffix: includeSuffix } );
	
}

export function sToDurationString( { 
	value, 
	includeSuffix = true, 
	preserveSign = true, 
	negate = false,
} = {} ) {
	
	/* 
	Integer value - duration in seconds; we most often look back in time, 
		so a positive value represents a time 'ago', while a negative 
		value is a duration into the future
	Boolean includeSuffix - TRUE will add ' ago' or ' from now' to the 
		string; FALSE will do nothing; Note, this takes priority over 
		preserveSign
	Boolean preserveSign - TRUE will prepend the string with a hyphen '-' 
		for a negative value IF includeSuffix is FALSE
	Boolean negate - TRUE will multiply value by -1 to negate the sign
	*/

	value = parseInt( value, 10 ) * ( negate ? -1 : 1 );

	const sign = value >= 0 ? 1 : -1;
	value = Math.abs( value );

	let durationString = '';
	const buildString = ( chunk, unit ) => {
		durationString = '';

		if ( ! includeSuffix && preserveSign && sign > 0 ) {
			durationString += '-';
		}
		durationString += Math.round( value / chunk ) + unit;
		if ( includeSuffix ) {
			durationString += ( sign > 0 ? ' ago' : ' from now' );
		}
	};

	if ( value < 60 ) { // 60s
		buildString( 1, 's' );
		// durationString = value + 's';
	}
	else if ( value < 60 * 60 ) { // 60m
		buildString( 60, 'm' );
		// durationString = Math.round( value / 60 ) + 'm';
	}
	else if ( value < 60 * 60 * 24 ) { // 24h
		buildString( 60 * 60, 'h' );
		// durationString = Math.round( value / 60 / 60 ) + 'h';
	}
	else if ( value < 60 * 60 * 24 * 7 ) { // 7d
		// durationString = Math.round( value / 60 / 60 / 24 ) + 'd';
		buildString( 60 * 60 * 24, 'd' );
	}
	else if ( value < 60 * 60 * 24 * 30 ) { // 30d
		// durationString = Math.round( value / 60 / 60 / 24 / 7 ) + 'w';
		buildString( 60 * 60 * 24 * 7, 'w' );
	}
	else if ( value < 60 * 60 * 24 * 365 ) { // 365d
		buildString( 60 * 60 * 24 * 30, 'mo' );
		// durationString = Math.round( value / 60 / 60 / 24 / 30 ) + 'mo';
	}
	else if ( value >= 60 * 60 * 24 * 365 ) { // 365d
		// durationString = Math.round( value / 60 / 60 / 24 / 365 ) + 'y';
		buildString( 60 * 60 * 24 * 365, 'y' );
	}
	
	return durationString;

}