

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { App, Button, Divider, Input, Select, Tag, Tooltip } from 'antd';
import { PLUS } from './icons';
import { AdminJSON } from '../utils/admin';
import axios from 'axios';
import { API_COLLECTIONS_URL } from '../utils';
// import _ from 'lodash';



export function TagsInput ( props ) {

	//
	const { 
		value, 
		onChange, 
		tagList = [], 
		collectionId, 
		refreshCollection,
		allowAddTag = true,
	} = props;

	const { message } = App.useApp();

	const [ newTagName, setNewTagName ] = useState( '' );

	// const MAX_TAG_LENGTH = 20;

	const tags = useMemo( () => {
		return value ?? [];
	}, [ value, ] );



	const handleChange = tags => {
		onChange( tags );
	};



	const handleAddTag = () => {

		axios.put( API_COLLECTIONS_URL + '/' + collectionId + '/update-tags',
		{ 
			tags: [ 
				...tagList,
				{ name: newTagName }
			] 
		},
		{
			headers: {
				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
				'Content-Type': 'application/json'
			}
		} )
		.then( data => {
			if ( data.status !== 200 ) {
						
				return;
			}

			setNewTagName( '' );

			message.success( 'Tag added to collection.' );

			onChange( [ ...tags, newTagName ] );

			// call for collection refetch 
			if ( typeof refreshCollection === 'function' ) refreshCollection();
		} )
		.catch( error => {
			console.log( error );
			message.error( 'Something went wrong. The tag may not have been added to the collection.' );
		} )
	};



	return <>
		
		<div className='my-2'>
			<Select
				className='w-100'
				mode='multiple'
				size='large'
				placeholder='Choose tags'
				value={value}
				onChange={handleChange}
				style={{}}
				options={tagList?.map( tag => ( { 
					value: tag.name,
					label: tag.name,
				} ) ) }
				dropdownRender={menu => ( 
					<>
						{menu}
						{allowAddTag ? <>
							<Divider className='mx-1' />
							<div className='d-flex'>
								<Input
									style={{flexGrow: 1,}}
									type='text'
									size='large'
									autoCapitalize='none'
									value={newTagName}
									onChange={e => setNewTagName( e.target.value )}
									onPressEnter={handleAddTag}
								/>
								<Button 
									type='text'
									icon={<PLUS />}
									onClick={handleAddTag}
									disabled={newTagName === '' || 
										tagList?.find( tag => ( tag.name + '' ).toLowerCase() === ( newTagName + '' ).toLowerCase() )}
								>
									Add tag
								</Button>
							</div>
						</> : null}
					</>
				 )}
			/>
		</div>

		<AdminJSON obj={tags} label='tags' />
		<AdminJSON obj={tagList} label='tagList' />
	</>;

}



export function TagsDisplay ( props ) {

	//

	const { 
		tagNames, 
		// tagsDefinition, 
	} = props;



	// const getTag = tagName => tagsDefinition.find( t => t.name === tagName );



	return <>
		<div>
			{tagNames?.map( tagName => <Tag key={tagName}>
				<span>{tagName}</span>
			</Tag>)}
		</div>
	</>;


}



export function TagsDefinition ( props ) {

	//
	const { value, onChange, } = props;

	const MAX_TAG_LENGTH = 20;



	const tags = useMemo( () => {
		return value ?? [];
	}, [ value, ] );



	const [ inputVisible, setInputVisible ] = useState( false );

	const [ inputValue, setInputValue ] = useState( '' );



	const inputRef = useRef( null );



	useEffect( () => {
		if ( inputVisible ) {
			inputRef.current?.focus();
		}
	}, [ inputVisible ] );



	const handleClose = removedTagName => {
		const newTags = tags.filter( tag => tag.name !== removedTagName );
		// console.log( 'newTags: ', newTags );
		onChange( newTags );
	};

	const showInput = () => {
		setInputVisible( true );
	};

	const handleInputChange = e => {
		setInputValue( e.target.value );
	};

	const handleInputConfirm = () => {
		if ( inputValue && ! tags.map( tag => tag.name ).includes( inputValue ) ) {
			onChange( [ ...tags, { name: inputValue } ] );
		}

		setInputVisible( false );
		setInputValue( '' );
	};


	return <>
		
		<div className='my-2'>
			{/* {tags && tags?.length ? tags.map( tag => { */}
			{tags.map( tag => {
				//
				const isLongTag = tag.name.length > MAX_TAG_LENGTH;
				const tagElement = (
					<Tag
						key={tag.name}
						closable={true}
						style={{ userSelect: 'none',}}
						onClose={() => handleClose( tag.name )}
					>
						<span>{isLongTag ? `${tag.name.slice( 0, MAX_TAG_LENGTH )}...` : tag.name}</span>
					</Tag>
				);

				return <React.Fragment key={tag.name}>
					{isLongTag ? (
						<Tooltip title={tag.name}>
							{tagElement}
						</Tooltip> ) :
						tagElement
					}
				</React.Fragment>;
			} )}

			{inputVisible ? (
				<Input
					ref={inputRef}
					type='text'
					size='small'
					style={{
						width: 64,
						height: 22,
						marginInlineEnd: 8,
						verticalAlign: 'top',
					}}
					value={inputValue}
					onChange={handleInputChange}
					onBlur={handleInputConfirm}
					onPressEnter={handleInputConfirm}
				/>
			) : (
				<Tag
					style={{
						height: 22,
						borderStyle: 'dashed',
					}}
					icon={<PLUS />}
					onClick={showInput}
				>
					Add tag
				</Tag>
			)}
		</div>

		<AdminJSON obj={tags} label='tags' />
	</>;

}