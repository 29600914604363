

import { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';


import { userIsAdmin } from '.';
import { ADMIN_BADGE } from '../components/icons';


export function AdminJSON( { obj = {}, label = null, } = {} ) {

	const [ objOpen, setObjOpen ] = useState( false );
	


	// only display for admin
	if ( ! userIsAdmin ) {
		return <></>;
	}



	return <>
		<div className='m-2'>
			<Button size='sm' variant='warning' onClick={() => setObjOpen( ! objOpen )}>
				<ADMIN_BADGE style={{fontSize: '1.5em'}} />
				{label ? label + ' ' : ''}
				{'JSON '}
				{objOpen ? <BsChevronUp /> : <BsChevronDown />}
			</Button>
			<Collapse in={objOpen} className='border border-warning rounded'>
				<pre className=''>{JSON.stringify( obj, null, '\t' )}</pre>
			</Collapse>
		</div>
	</>;

}

