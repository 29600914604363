

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BsHddNetwork, BsListUl, } from 'react-icons/bs';
import { HiSquares2X2, } from 'react-icons/hi2';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Event from '../components/Event';
// import UserDisplay from '../components/users/UserDisplay';
import { deepEqual, API_COLLECTIONS_URL } from '../utils';

// Filter
import { Collapse, } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
// import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { AdminJSON } from '../utils/admin';


// import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Timeline from '../components/Timeline';
import dayjs from 'dayjs';
// import { MAGNIFYING_GLASS, PLUS, REFRESH, SETTINGS_COG, } from '../components/icons';
import { InputSelect, InputText } from '../components/Input';
import { dataTypes } from '../utils/dictionary';
import SortEvents from '../components/SortEvents';
import CollectionHeader from '../components/collections/CollectionHeader';
// import { TagsInput } from '../components/Tags';



export default function ViewCollectionEvents() {

	const { collectionId } = useParams();

	const [ searchParams, setSearchParams ] = useSearchParams();
	
	const navigate = useNavigate();

	const [ collection, setCollection ] = useState({
		name: '',
		// detail: '',
		ownedBy: '',
		sharedWith: [],
		definition: {
			elements: []
		}
	});

	const [ events, setEvents ] = useState([]);

	const [ filterOpen, setFilterOpen ] = useState( false );

	const [ filter, setFilter ] = useState( {} );
	const [ syncFilterWithSearchParams, setSyncFilterWithSearchParams ] = useState( true );

	const [ display, setDisplay ] = useState( searchParams.has( 'view' ) && [ 'cards', 'timeline', 'list', ].includes( searchParams.get( 'view' ) ) ? searchParams.get( 'view' ) : 'cards' );

	const [ triggerRefreshEvents, setTriggerRefreshEvents ] = useState( false );
	
	const [ sortAscending, setSortAscending ] = useState( false );

	const [ sortBy, setSortBy ] = useState( 'lastUpdate' );

	const [ sortOptions, setSortOptions ] = useState( [] );


	const sortEvents = useCallback(() => {

		const elementSortDate = ( a, b ) => {
			let retVal;
			if ( sortBy === 'lastUpdate' || sortBy === 'createdOn' ) {
				retVal = dayjs( a[ sortBy ] ).valueOf() - dayjs( b[ sortBy ] ).valueOf();
			}
			else {
				retVal = dayjs( a.elements[ sortBy ] ).valueOf() - dayjs( b.elements[ sortBy ] ).valueOf();
			}
			return retVal;  // ascending
		};

		const elementSortDefault = ( a, b ) => {
			if ( a.elements[ sortBy ] < b.elements[ sortBy ] ) {
				return -1;
			}
			if ( a.elements[ sortBy ] > b.elements[ sortBy ] ) {
				return 1;
			}

			return 0;
			// return a.elements[ sortBy ] - b.elements[ sortBy ];
		};

		

		setEvents( prevEvents => {
			const tempArr = [ ...prevEvents ];
			const elementDataType = ( [ 'createdOn', 'lastUpdate', ].indexOf( sortBy ) > -1 ? 'date-time' :
				collection.definition.elements.find( e => e.name === sortBy )?.dataType );
			// console.log( elementDataType );
			
			switch ( elementDataType ) {
				case 'date':
				case 'date-time':
					console.log( 'sort date' );
					tempArr.sort( elementSortDate );
					break;
				default: 
					console.log( 'sort default' );
					tempArr.sort( elementSortDefault );
			}

			tempArr.sort( elementSortDate );
			if ( ! sortAscending ) {
				tempArr.reverse(); // descending
			}
			return tempArr;
		} );

	}, [ sortAscending, sortBy, collection.definition.elements, ]);



	
	useEffect( () => {

		if ( ! syncFilterWithSearchParams ) {
			return;
		}
		else {
			setSyncFilterWithSearchParams( false );
		}

		if ( ! searchParams.has( 'ff' ) || searchParams.get( 'ff' ) === '' ) {
			// baseline filter from element definition
			setFilter( Object.fromEntries( collection.definition.elements.map( e => [ e.name, '' ] ) ) );
		}
		else {
			// `ff` query param is defined, use that
			const params = searchParams.has( 'ff' ) ? Object.fromEntries( Array.from( ( searchParams.get( 'ff' ) + '' ).split( '&' ), e => e.split( '=' ) ) ) : {};
			
			// handle keys removed from searchParams - set value to '' rather than deleting key to maintain state in the form
			for ( let key in filter ) {
				if ( ! params.hasOwnProperty( key ) ) {
					// key exists in filter, removed from searchParams - add key to params obj with empty value
					params[ key ] = '';
				}
			}
			
			if ( ! deepEqual( filter, params ) ) {
				setFilter( params );
			}
		}


	}, [collection.definition.elements, filter, searchParams, syncFilterWithSearchParams] );


	

	//////////////////////////////////////////////////
	//   
	//   GET
	//   
	//////////////////////////////////////////////////
	
	// GET COLLECTION
	useEffect( () => {
		axios.get( API_COLLECTIONS_URL + '/' + collectionId, {
			headers: {
				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
				'Content-Type': 'application/json'
			}
		} )
		.then( data => {
			if ( data.status !== 200 ) {
				return false;
			}
			
			setSyncFilterWithSearchParams( true );
			setCollection( data.data );
		} )
		.catch( error => {
			if ( error.response.status === 401 ) {
				navigate( '/login?isRedirect=true' );
			}
		} );
	}, [collectionId, navigate, ] );
	


	// GET EVENTS
	useEffect( () => {
		if ( triggerRefreshEvents ) {
			setEvents( [] );
		}

		axios.get( API_COLLECTIONS_URL + '/' + collectionId + '/events', {
			headers: {
				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
				'Content-Type': 'application/json'
			},
			params: { filter: JSON.stringify( searchParams.has( 'ff' ) ? Object.fromEntries( Array.from( ( searchParams.get( 'ff' ) + '' ).split( '&' ), e => e.split( '=' ) ) ) : {} ) }
		} )
			.then( data => {
				// console.log( JSON.stringify( data, null, '\r' ) );
				if ( data.status !== 200 ) {
					return false;
				}

				// sort descending on created DT
				if ( data.data && data.data.constructor === Array ) {
					// data.data.sort( ( a, b ) => dayjs( b.createdOn ).valueOf() - dayjs( a.createdOn ).valueOf() );
				}

				setEvents( data.data );
				sortEvents();

				setTriggerRefreshEvents( false );
			} )
			.catch( error => {
				// 
				if ( error.response.status === 401 ) {
					navigate( '/login?isRedirect=true' );
				}
			} );
	}, [ collectionId, navigate, searchParams, triggerRefreshEvents, sortEvents, ] );



	// SORT EVENTS
	useEffect( () => {
		sortEvents();
	}, [ sortBy, sortAscending, sortEvents, ] );

	useEffect( () => {
		let elements;
		if ( display === 'timeline' ) {
			elements = collection.definition.elements.filter( e => [ 'date', 'date-time', ].includes( e.dataType ) );
			// elements = collection.definition.elements.filter( e => e.dataType === 'date' );
		}
		else {
			elements = collection.definition.elements;
		}

		// const options = collection.definition.elements.filter( e => e.dataType === 'date' ).map( e => ( { value: e.name, label: e.label.singular, } ) );
		const options = elements.map( e => ( { 
			value: e.name, 
			label: <><span>{dataTypes.find( dtEle => dtEle.name === e.dataType )?.bsIconJSX} {e.label.singular}</span></>, 
		} ) );
		options.push( { value: 'createdOn', label: 'Created on' } );
		options.push( { value: 'lastUpdate', label: 'Updated on' } );

		setSortBy( options[ 0 ].value );
		setSortOptions( options );
		//setSortBy( collection.elements.filter( e => e.dataType === 'date' ))
	}, [ collection, display, ] );



	const setView = ( view ) => {
		setDisplay( view );
		searchParams.set( 'view', view );
		setSearchParams( searchParams );
	};
	


	const setSearchParamsFromFilter = () => {
		// remove keys with empty values
		const tempFilter = {...filter};
		for ( let key in tempFilter ) {
			if ( tempFilter[ key ] === '' || tempFilter[ key ] === null || key === '' ) {
				delete tempFilter[ key ];
			}
		}
	
		searchParams.set( 'ff', new URLSearchParams( tempFilter ).toString() );
		setSearchParams( searchParams ); // TODO - better way to do this ? ? ?
		// setSearchParams( { ff: new URLSearchParams( tempFilter ).toString() } );
	};

	const handleFilterSubmit = event => {
		//
		event.preventDefault();

		setSyncFilterWithSearchParams( true ); // allow searchParams to be authoritative

		setSearchParamsFromFilter();
	};

	const handleResetFilter = () => {
		// TODO - only clear 'ff' param, not all
		setSyncFilterWithSearchParams( true );
		searchParams.delete( 'ff' );
		setSearchParams( searchParams );
	};

	const handleFilterChange = ( elementName, value ) => {
		setSyncFilterWithSearchParams( false ); // allow filter state to be authoritative
		setFilter( prevState => ( { ...prevState, [ elementName ]: value } ) );
		// setFilter( prevState => ({ ...prevState, [e.target.name]: e.target.value}));
	};

	const disableFilterSubmit = () => {
		// TODO - broken
		return false;
		// return searchParams.get( 'ff' ) === new URLSearchParams( filter ).toString();
	};



	const getFilterFormInput = elementDef => {
		// CHOICE
		if ( elementDef.options.choices && elementDef.options.choices.length ) {
			return <>
				<InputSelect
					elementName={elementDef.name}
					elementLabel={elementDef.label.singular}
					value={filter[ elementDef.name ]}
					onChange={handleFilterChange}
					options={[ { value: '', label: 'All ' + elementDef.label.plural }, ...elementDef.options.choices ]}
				/>
			</>;
		}

		// TEXT
		if ( elementDef.dataType === 'text-small' || elementDef.dataType === 'text-large' ) {
			return <>
				<InputText
					elementName={elementDef.name}
					elementLabel={elementDef.label.singular}
					value={filter[ elementDef.name ]}
					onChange={handleFilterChange}
				/>
			</>;
			
		}

		// NUMBER

		// DATE

		return (<>
		</>);
	};

	

	return ( <>

		<CollectionHeader 
			collection={collection} 
			events={events}
			toggleFilterOpen={() => setFilterOpen( ! filterOpen )}
			triggerRefresh={() => setTriggerRefreshEvents( true )}
		/>
		{/* <div className='d-flex'>
			<h1 className='d-inline-block ' style={{flexGrow: 1}}>
				{collection.name}
				<span className='mx-2' style={{fontSize: '60%',}}>
					({events.length})
				</span>
				</h1>

			<Button className='p-1 m-1' onClick={() => navigate( '/collections/' + collection._id + '/events/-1' )}>
				<PLUS className='medium-icon' title='New item' />
			</Button>

			<Button className='p-1 m-1' onClick={() => setFilterOpen( ! filterOpen )}>
				<MAGNIFYING_GLASS className='medium-icon' title='Collection settings' />
			</Button>

			<Button className='p-1 m-1' onClick={() => setTriggerRefreshEvents( true )}>
				<REFRESH className='medium-icon' title='Collection settings' />
			</Button>

			<Button className='p-1 m-1' onClick={() => navigate( '/collections/' + collection._id + '/form')}>
				<SETTINGS_COG className='medium-icon' title='Collection settings' />
			</Button>
		</div> */}

		

		<Collapse in={filterOpen}>
			<div>
				<h2>Filter</h2>
				<Form onSubmit={handleFilterSubmit}>
					
					{collection.definition.elements.map( element => <React.Fragment key={element.name}>
						{getFilterFormInput(element)}
					</React.Fragment> )}

					{/* <TagsInput 
						tagList={collection.definition.tags} 
						onChange={() => {}}
						/> */}

					<Button variant='primary' type='submit' disabled={disableFilterSubmit()}>
						Apply filter
					</Button>
					<Button variant='danger' onClick={handleResetFilter}>
						Reset filter
					</Button>
					
				</Form>

				<AdminJSON obj={filter} label={'filter'} />
			</div>
		</Collapse>



		<div className='d-flex justify-content-center'>
			<ButtonGroup className='m-2'>
				<Button 
					className='p-1 px-2'
					variant={display === 'cards' ? 'secondary' : 'outline-secondary'} 
					onClick={() => setView( 'cards' )}>
					<HiSquares2X2 className='' style={{fontSize: '1.5em',}} /> Cards
				</Button>
				<Button 
					className='p-1 px-2'
					variant={display === 'timeline' ? 'secondary' : 'outline-secondary'} 
					onClick={() => setView( 'timeline' )}>
					<BsHddNetwork className='' style={{fontSize: '1.5em',}} /> Timeline
				</Button>
				<Button 
					className='p-1 px-2'
					variant={display === 'list' ? 'secondary' : 'outline-secondary'} 
					onClick={() => setView( 'list' )}>
					<BsListUl className='' style={{fontSize: '1.5em',}} /> List
				</Button>
			</ButtonGroup>
		</div>



		{display === 'cards' ? <>
			<SortEvents sortBy={sortBy} setSortBy={setSortBy} sortOptions={sortOptions} sortAscending={sortAscending} setSortAscending={setSortAscending} />
			
			<Row xs={1} md={2} lg={3} xxl={4} className="g-4">
				{events.map( event => <Col key={event._id}>
					<Event 
						event={event} 
						elements={collection.definition.elements} 
						tagsDefinition={collection.definition?.tags}
					/>
				</Col> )}
			</Row> 
		</> : null}

		{display === 'timeline' ? <>
			<SortEvents 
				sortBy={sortBy} 
				setSortBy={setSortBy} 
				sortOptions={sortOptions} 
				sortAscending={sortAscending} 
				setSortAscending={setSortAscending} 
				// dateOnly={true}
				/>
			
			<Timeline 
				events={events} 
				defElements={collection.definition.elements} 
				sortBy={sortBy}
				/>
		</> : null}

		{display === 'list' ? <div>List, coming soon</div> : null}



		<AdminJSON obj={events} label={'events'} />
		<AdminJSON obj={collection} label={'collection'} />

	</> );

};