
// import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from "react-router-dom";

import EventForm from '../components/events/EventForm';



export default function ViewEvent() {

	// 

	const { collectionId, eventId } = useParams();



	return (<>
		<EventForm eventId={eventId} collectionId={collectionId} />
	</>);

}