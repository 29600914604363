
import * as IconsMd from 'react-icons/md';
import * as IconsTb from 'react-icons/tb';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { RATING_STAR_FILL, RATING_STAR_OUTLINE } from './icons';
import { sToDurationString } from '../utils/date';

export default function EventElement( props ) {

	const {
		event,
		element,
		display = 'cards',
	} = props;

	const icon = useMemo( () => {
		if ( element.options ) {
			if ( element.options.choices ) {
				const choices = element.options.choices;
				for ( let i = 0; i < choices.length; ++i ) {
					if ( choices[ i ].value === event.elements[ element.name ] ) {
						let Icon;
						if ( choices[ i ].icon ) {
							const iconPrefix = ( choices[ i ].icon + '' ).toLowerCase().slice( 0, 2 );
							switch ( iconPrefix ) {
								case 'md' : Icon = IconsMd[ choices[ i ].icon ]; break;
								case 'tb' : Icon = IconsTb[ choices[ i ].icon ]; break;
								default : Icon = null;
							}
							
							// const Icons = iconPrefix === 'md' ? IconsMd : IconsTb;
							// Icon = Icons[ choices[ i ].icon ];
						}
						return (<>
							{Icon ? <Icon /> : null}
						</>);
					}
				}
			}
		}

		return null;
	}, [element.name, element.options, event.elements]);



	const elementColor = useMemo( () => {
		if ( element.options ) {
			if ( element.options.choices ) {
				const choices = element.options.choices;
				for ( let i = 0; i < choices.length; ++i ) {
					if ( choices[ i ].value === event.elements[ element.name ] ) {
						if ( choices[ i ].hasOwnProperty( 'color' ) ) {
							return choices[ i ].color;
						}
						return null;
					}
				}
			}
		}

		return null;
	}, [element.name, element.options, event.elements]);



	const hasChoices = element.options && element.options.choices && element.options.choices.length;



	const getRatingsDisplay = ( rating ) => {
		const NUM_STARS = 5;
		const stars = [];

		for ( var sIdx = 0; sIdx < NUM_STARS; ++sIdx ) {
			stars.push( sIdx < rating ? <RATING_STAR_FILL /> : <RATING_STAR_OUTLINE /> );
		}

		return <>
			{stars.map( star => star)}
		</>;
	};


	
	const displayValue = () => {
		// TODO - move to DateString 
		const val = event.elements[element.name];
		// console.log( element.name, val );

		if ( ! val ) return '';

		if ( element.dataType === 'date' ) {
			// return 'foo';
			return dayjs( val ).format( 'DD-MMM-YYYY' );
		}
		else if ( element.dataType === 'date-time' ) {
			const secondsAgo = Date.now() / 1000 - dayjs( val ).unix();
			return <>
				{dayjs( val ).format( 'DD-MMM-YYYY h:mma' )}
				<span className='mx-2' style={{fontSize: '.8rem',}}>
					{'(' + sToDurationString( { value: secondsAgo, } ) + ')'}
				</span>
			</>;
		}
		else if ( element.dataType === 'rating' ) {
			return getRatingsDisplay( val );
		}

		// console.log( 'value' );
		// console.log( val );
		return val;
	};



	const getElementValue = () => {
		//

		const eleValue = <span className='element-value'>
			{elementColor ? <span className='dot' style={{backgroundColor: elementColor}}></span> : null}
			{icon ? <span className='element-icon'>{icon}</span> : null}
			
			<span>{hasChoices ? element.options.choices.find( c => c.value === event.elements[element.name] )?.label : displayValue()}</span>
		</span>;

		const isURL = element.dataType === 'url';

		if ( isURL ) {
			return <a href={displayValue()} target='_blank' rel='noreferrer noopener'>
				{eleValue}
			</a>
		}

		return <>{eleValue}</>;
	};



	return (<>

		{displayValue() && display === 'cards' ? <>
			<div className=''>
				<span className='mx-2'>{element?.label?.singular}</span>

				<div className='' style={{
					borderColor: elementColor, 
					backgroundColor: elementColor,
					margin: '0 4px 4px',
					borderRadius: '7px',
					borderWidth: '1px',
					borderStyle: 'solid',
				}}>
					<div className='' style={{
						padding: '4px',
						borderRadius: '6px',
						backgroundColor: 'rgba(255, 255, 255, .8)',
						whiteSpace: 'pre-wrap',
					}}>
						{getElementValue()}
					</div>

				</div>
			</div>
		</> : null}

		{displayValue() && display === 'timeline' ? <>
			<div className=''>
				<span>{element?.label?.singular}</span>
				<span>{getElementValue()}</span>
			</div>
		</> : null}

		

	</>)

};