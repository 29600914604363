

import { Button } from "antd";
import { MAGNIFYING_GLASS, PLUS, REFRESH, SETTINGS_COG } from "../icons";
import { useNavigate } from "react-router-dom";



export default function CollectionHeader( props ) {

	const { 
		collection, 
		events,
		toggleFilterOpen,
		triggerRefresh,
	} = props;

	const navigate = useNavigate();

	return ( <>
		<div className='d-flex'>
			<h1 className='d-inline-block ' style={{flexGrow: 1}}>
				{collection.name}
				<span className='mx-2' style={{fontSize: '60%',}}>
					({events.length})
				</span>
				</h1>

			<Button className='p-1 m-1' onClick={() => navigate( '/collections/' + collection._id + '/events/-1' )}>
				<PLUS className='medium-icon' title='New item' />
			</Button>

			<Button className='p-1 m-1' onClick={toggleFilterOpen}>
				<MAGNIFYING_GLASS className='medium-icon' title='Collection settings' />
			</Button>

			<Button className='p-1 m-1' onClick={triggerRefresh}>
				<REFRESH className='medium-icon' title='Collection settings' />
			</Button>

			<Button className='p-1 m-1' onClick={() => navigate( '/collections/' + collection._id + '/form')}>
				<SETTINGS_COG className='medium-icon' title='Collection settings' />
			</Button>
		</div>
	</> );

}