

import { useParams } from 'react-router';

import CollectionForm from '../components/collections/CollectionForm';

export default function ViewCollection() {

	//
	const { collectionId } = useParams();


	return (<>

		<CollectionForm collectionId={collectionId} />

	</>);

}