

import { CALENDAR, CALENDAR_CLOCK, CLOCK, NUM_123, RATING_STAR, TEXT, TEXT_MULTI, URL_ICON } from '../components/icons';



export const dataTypes = [
	{
		name: 'text-small',
		label: 'Text - short',
		bsIcon: 'BiText',
		bsIconJSX: <TEXT />,
	},
	{
		name: 'text-large',
		label: 'Text - long',
		bsIcon: 'BsTextWrap',
		bsIconJSX: <TEXT_MULTI />,
	},
	{
		name: 'number',
		label: 'Number',
		bsIcon: 'Bs123',
		bsIconJSX: <NUM_123 />,
	},
	{
		name: 'date',
		label: 'Date',
		bsIcon: 'BsCalendarDate',
		bsIconJSX: <CALENDAR />,
	},
	{
		name: 'date-time',
		label: 'Date & time',
		bsIcon: '',
		bsIconJSX: <CALENDAR_CLOCK />,
	},
	{
		name: 'time',
		label: 'Time',
		bsIcon: '',
		bsIconJSX: <CLOCK />,
	},
	{
		name: 'url',
		label: 'URL',
		bsIcon: '',
		bsIconJSX: <URL_ICON />,
		allowMultiple: true,
	},
	{
		name: 'rating',
		label: 'Rating',
		bsIcon: '',
		bsIconJSX: <RATING_STAR />,
	},
];

export const getDictionaryFromName = ( name ) => {
	return dataTypes.find( ele => ele.name === name );
}



export const elementColors = [
	{ value: 'Olive', label: 'Olive' },
	{ value: 'ForestGreen', label: 'Forest green' },
	{ value: 'LimeGreen', label: 'Lime green' },
	{ value: 'PaleGreen', label: 'Pale green' },
	{ value: 'Gold', label: 'Gold' },
	{ value: 'DarkOrange', label: 'Orange' },
	{ value: 'Tomato', label: 'Tomato' },
	{ value: 'Crimson', label: 'Crimson' },
	{ value: 'DeepPink', label: 'Pink' },
	{ value: 'Violet', label: 'Violet' },
	{ value: 'DarkMagenta', label: 'Purple' },
	{ value: 'PaleTurquoise', label: 'Light blue' },
	{ value: 'DodgerBlue', label: 'Blue' },
	{ value: 'DarkCyan', label: 'Dark cyan' },
	{ value: 'Aqua', label: 'Aqua' },
	{ value: 'Wheat', label: 'Wheat' },
	{ value: 'Tan', label: 'Tan' },
	{ value: 'SandyBrown', label: 'Sandy brown' },
	{ value: 'Chocolate', label: 'Light brown' },
	{ value: 'SaddleBrown', label: 'Dark brown' },
	{ value: 'LightGray', label: 'Light gray' },
	{ value: 'DarkGray', label: 'Dark gray' },
];



// export const emailRE = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;


