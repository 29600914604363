

import { Link } from 'react-router-dom';

import { Popover, } from 'antd';

import { getEmail, getFullName, getTokenExpiry, isTokenValid, userIsAdmin } from '../utils';
import { sToDurationString } from '../utils/date';
import { PERSON } from './icons';
import { COLOR_SECONDARY, COLOR_WHITE } from '../utils/colors';
import { useState } from 'react';



// export default function HeaderDetail() {
const HeaderDetail = () => {
		
	//
	
	const tokenExpiryString = () => {
		return sToDurationString( { value: getTokenExpiry( { relative: true } ), negate: true } );
	};
	
	return ( <>
		{userIsAdmin ? <span className='d-none d-md-inline' style={{color: COLOR_WHITE,}}>
			{' [' + process.env.NODE_ENV + '] Token exp: ' + tokenExpiryString()}
		</span> : null}

		<Avatar />
	</> );

}
export default HeaderDetail;



const Avatar = () => {

	// 

	const [ avatarPopoverOpen, setAvatarPopoverOpen ] = useState( false );

	return ( <>
	
		<div className='rounded px-2 py-1 mx-2' style={{
			backgroundColor: COLOR_SECONDARY,
			border: '2px solid ' + COLOR_WHITE,
			fontSize: '1.25em',
		}}>
			<Popover
				content={isTokenValid() ? <AvatarPopup /> : <AvatarPopupLogin />}
				title={null}
				trigger='click'
				placement='bottomRight'
				open={avatarPopoverOpen}
				onOpenChange={( newOpen ) => setAvatarPopoverOpen( newOpen )}
			>
				<PERSON style={{fontSize: '2em', color: COLOR_WHITE, }} />
			</Popover>
			{/* <Link className='profile-link text-decoration-none' to='/profile' style={{color: COLOR_WHITE,}}>
				<PERSON style={{fontSize: '2em',}} />
			</Link> */}
		</div>
	
	</> );

};



const AvatarPopup = () => {

	//



	return ( <>
		<div className='avatar-popover-container'>
			<div className='d-flex'>
				{/* <span>
					<PERSON />
				</span> */}
				<span style={{flexGrow: 1, }}>
					{getFullName()}<br />
					<span className='text-muted'>{getEmail()}</span>
				</span>
			</div>
			<Link to='/profile'>
				Profile
			</Link>
			<br />
			<Link to='/logout'>
				Logout
			</Link>
		</div>
	</> );
};



const AvatarPopupLogin = () => {

	//



	return ( <>
		<div className='avatar-popover-container'>
			<Link to='/login'>
				Log in
			</Link>
		</div>
	</> );
}