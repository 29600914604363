

import { Skeleton } from 'antd';



export function LoadingList () {

	//
	// const skeletonLine = () => <Skeleton.Input
	// 	active
	// 	title={false}
	// 	block
	// />;


	
	return <>
		<Skeleton 
			active
			title={false}
			paragraph={{
				rows: 2,
				width: '100%',
			}}
		/>
		{/* <Skeleton.Input
			className='my-1'
			size='small'
			active
			title={false}
			block
			/>
		<Skeleton.Input
			className='my-1'
			size='small'
			active
			title={false}
			block
		/> */}
	</>;

}