

// import { InputNumber } from 'antd';
import { InputDate, InputNum, InputRating, InputSelect, InputText, InputTextArea } from '../Input';
// import { CHEVRON_DOWN, CHEVRON_UP } from '../icons';

export default function EventFormElement( { event, element, value, onChange } ) {

	//

	const options = element.options || { choices: [] };

	const hasChoices = options.choices.length > 0 || false;

	const hasSelectIcons = options?.selectIcons || false;

	const handleChange = ( name, value ) => onChange( name, value );



	// const fullChoices = options.choices.map( choice => ( {
	// 	...choice,
	// 	iconJSX: <></>,
	// } ) );
	



	return (<>
		{ element.dataType === 'text-small' && ! hasChoices ? <InputText elementName={element.name} elementLabel={element.label.singular} value={value} onChange={handleChange} /> : null}
		{ element.dataType === 'text-small' && hasChoices ? <InputSelect elementName={element.name} elementLabel={element.label.singular} value={value} onChange={handleChange} options={options.choices} selectIcons={hasSelectIcons} /> : null}
		{ element.dataType === 'text-large' ? <InputTextArea elementName={element.name} elementLabel={element.label.singular} value={value} onChange={handleChange} /> : null}
		{ element.dataType === 'date' ? <InputDate elementName={element.name} elementLabel={element.label.singular} value={value} onChange={handleChange} /> : null}
		{ element.dataType === 'date-time' ? <InputDate elementName={element.name} elementLabel={element.label.singular} value={value} onChange={handleChange} showTime={true} /> : null}
		{ [ 'number', 'decimal', 'integer', ].indexOf( element.dataType ) > -1 ? <InputNum elementName={element.name} elementLabel={element.label.singular} value={value} onChange={handleChange} /> : null}
		{ element.dataType === 'url' ? <InputText elementName={element.name} elementLabel={element.label.singular} value={value} onChange={handleChange} /> : null}
		{ element.dataType === 'rating' ? <InputRating elementName={element.name} elementLabel={element.label.singular} value={value} onChange={handleChange} /> : null}

		{/* { element.dataType === 'number' ? <InputNumber controls={{upIcon: CHEVRON_UP, downIcon: CHEVRON_DOWN,}} min={1} max={20} value={value} onChange={handleChange} /> : null} */}
	</>);

};