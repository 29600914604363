

import { useState, useEffect, } from 'react';

import { Collapse, Button, } from 'antd';
// import Button from 'react-bootstrap/Button';
import _ from 'lodash';

import { BOY, CAR, CLOSE, DOG, FILTER, FOOTBALL, GIRL, HOME, PLUS, SCHOOL, UFO } from '../icons';
import { AdminJSON } from '../../utils/admin';
import { elementColors } from '../../utils/dictionary';
import { InputText, InputSelect } from '../Input';



export default function ChoiceList( { choices, onChange } ) {

	//
	// console.log( 'init: ', JSON.stringify( choices, null, '\t' ) );
	const [ state, setState ] = useState( choices );

	const handleChange = ( choice ) => {
		
		setState( prevState => {
			let foundMatch = false;
			let temp = [];
			for ( let t = 0; t < prevState.length; ++t ) {
				temp.push( prevState[ t ] );
			}

			for ( let i = 0; i < temp.length; ++i ) {
				if ( ( ! choice.prevValue && choice.value === temp[ i ].value ) || // typical, non 'value' change
					( ( choice.prevValue || choice.prevValue === '' ) && // handle empty string 'prevValue' - new choice with empty values
					( choice.prevValue === temp[ i ].value || // first time passing prevValue, coalesce on old 'value'
					choice.prevValue === temp[ i ].prevValue ) ) ) { // subsequent prevValue, coalesc on prevValue
					// match found
					foundMatch = true;

					temp[ i ] = choice;
					
					return temp;
				}
			}
	
			if ( ! foundMatch ) {
				// add new element to array
				prevState.push( choice );

				return prevState;
			}

			return prevState;
		});
		
	};

	const handleNewChoice = () => {setState( [ ...choices, {
			value: '',
			label: '',
			color: '',
			icon: ''
		} ] );
	};

	const handleDeleteChoice = ( choiceName ) => {
		setState( state.filter( e => e.value !== choiceName ) );
	};


	
	const getIcon = iconName => {
		switch ( iconName + '' ) {
			case 'MdOutlineGirl' : return <GIRL />;
			case 'MdOutlineBoy' : return <BOY />;
			case 'MdOutlineSchool' : return <SCHOOL />;
			case 'MdSportsFootball' : return <FOOTBALL />;
			case 'MdOutlineHome' : return <HOME />;
			case 'MdDirectionsCar' : return <CAR />;
			case 'MdFilterDrama' : return <FILTER />;
			case 'TbUfo' : return <UFO />;
			case 'TbDog' : return <DOG />;
			default: return null;
		}
	};



	const getColor = colorName => {
		return <span className='dot' style={{backgroundColor: colorName}}></span>;
	}


		
	useEffect( () => {
		if ( ! _.isEqual( state, choices ) ) {
			onChange( 'choices', state );
		}
	}, [choices, onChange, state] );
	


	return (<>

		{choices.map( ( e, idx ) =><Collapse
			key={idx}
			// items={cItems}
			className='m-2'
			items={[
				{
					key: idx,
					label: <>
						{getColor( e.color )}
						{getIcon( e.icon )}
						{e.label}
					</>,
					children: <ChoiceListItem 
						idx={idx} 
						choice={e} 
						onChange={handleChange} 
						onDelete={handleDeleteChoice} 
						/>,
				}
			]}
			/> )}

		<div className='d-flex'>
			<Button className='flex-grow-1 flex-md-grow-0' onClick={handleNewChoice} title='New choice'>
				<PLUS /> New choice
			</Button>
		</div>

		<AdminJSON obj={choices} label='choices' />
		<AdminJSON obj={state} label='state' />
	</>);

};



export function ChoiceListItem( { choice, onChange, onDelete, idx } ) {

	//

	const [ state, setState ] = useState( choice );



	const icons = [
		{ value: 'MdOutlineGirl', label: 'Girl' },
		{ value: 'MdOutlineBoy', label: 'Boy' },
		{ value: 'MdOutlineSchool', label: 'School' },
		{ value: 'MdSportsFootball', label: 'Football' },
		{ value: 'MdOutlineHome', label: 'Home' },
		{ value: 'MdDirectionsCar', label: 'Car' },
		{ value: 'MdFilterDrama', label: 'Cloud' },
		{ value: 'TbUfo', label: 'UFO' },
		{ value: 'TbDog', label: 'Dog' },
	];

	const colors = elementColors.map( color => ( {
		value: color.value,
		label: <>
			<span className='dot' style={{backgroundColor: color.value,}}></span>
			{color.label}
		</>
	} ) );



	const handleChange = ( name, value ) => {
		setState( prevState => {
			if ( name === 'value' ) {
				if ( ! prevState.prevValue ) {
					// save original value
					prevState.prevValue = prevState.value;
				}
				else if ( prevState.prevValue === value) {
					// changed back to original, delete
					delete prevState.prevValue;
				}
			}

			// if ( prevState.hasOwnProperty( 'prevValue' ) && prevState.prevValue === '' ) {
			// 	delete prevState.prevValue;
			// }

			console.log( 'handleChange before return: ', JSON.stringify( { ...prevState, [ name ]: value }, null, '\t' ) );

			return { ...prevState, [ name ]: value };
		} );
		
	};

	const handleDelete = () => {
		onDelete( choice.value );
	};



	useEffect( () => {
		if ( ! _.isEqual( state, choice ) ) {
			onChange( state );
		}
	}, [choice, onChange, state] );



	return (<>
		<InputText 
			elementName='value' 
			elementLabel='Value' 
			value={choice.value} 
			onChange={handleChange} 
		/>
		<InputText 
			elementName='label' 
			elementLabel='Label' 
			value={choice.label} 
			onChange={handleChange} 
		/>
		<InputSelect 
			elementName='color' 
			elementLabel='Color' 
			value={choice.color} 
			onChange={handleChange} 
			options={colors} 
		/>
		<InputSelect 
			elementName='icon' 
			elementLabel='Icon'
			value={choice.icon} 
			onChange={handleChange}
			options={icons} 
		/>
		
		<Button danger={true} onClick={handleDelete} title='Remove choice'>
			<CLOSE /> Delete choice
		</Button>
	</>);

};