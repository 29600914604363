import { Link } from "react-router-dom";




export default function Logout() {

	//

	window.localStorage.removeItem( 'token' );

	return <>
		<h1>You have been logged out</h1>
		<Link to='/login'>
			Log in
		</Link>
	</>;

}