

import { useState, useEffect } from 'react';

// import ChoiceList from './ChoiceList';
import { InputSwitch } from '../Input';
import { dataTypes } from '../../utils/dictionary';

export default function ElementOptions( { options, onChange, elementDefName, elementValue, } ) {

	// 

	const [ state, setState ] = useState( options || {} );

	if ( ! state.hasOwnProperty( 'required' ) ) {
		setState( prevState => ( { ...prevState, required: false } ) );
	}
	if ( ! state.hasOwnProperty( 'isTitle' ) ) {
		setState( prevState => ( { ...prevState, isTitle: false } ) );
	}



	const handleChange = ( key, value ) => {
		setState( prevState => ( { ...prevState, [ key ]: value } ) );
	};

	const handleChangeSwitch = ( key, value ) => {
		handleChange( ( key + '' ).split( '-' )[ 1 ], value );
	}



	useEffect( () => {
		if ( state !== options ) {
			onChange( 'options', state );
		}
	}, [onChange, options, state] );



	const dataTypeAllowsMultiple = dataTypes.find( ele => ele.name === elementValue.dataType )?.allowMultiple;
	


	return ( <>

		{/* <h5>Advanced options</h5> */}
		<InputSwitch elementName={elementDefName + '-required'} elementLabel={'Required'} value={state.required} onChange={handleChangeSwitch} />
		{/* <InputSwitch elementName={elementDefName + '-isTitle'} elementLabel={'Title element'} value={state.isTitle} onChange={handleChangeSwitch} /> */}
		
		{dataTypeAllowsMultiple ? 
			<InputSwitch elementName={elementDefName + '-allowMultiple'} elementLabel={'Allow multiple'} value={state?.allowMultiple} onChange={handleChangeSwitch} />
			: null}
		
		<InputSwitch 
			elementName={elementDefName + '-selectIcons'}
			elementLabel={'Use icons'}
			value={state?.selectIcons}
			onChange={handleChangeSwitch}
		/>

		{/* <h5>Choices</h5> */}
		{/* <ChoiceList choices={state.choices} onChange={handleChange} /> */}

	</> );

};