

import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { MdEdit } from 'react-icons/md';

import EventElement from "./EventElement";
import DateString from './DateString';
import { msToDurationString } from '../utils/date';
import { AdminJSON } from '../utils/admin';
import { TagsDisplay } from './Tags';

export default function Event( props ) {

	const { event, elements = [], tagsDefinition, } = props;

	

	const NUM_CHARS_CARD_TITLE = 40;

	const TRUNCATION_INDICATOR = '...';

	

	const navigate = useNavigate();

	elements.sort( ( a, b ) => {
		if ( a.displayOrder < b.displayOrder ) return -1;
		if ( a.displayOrder > b.displayOrder ) return 1;
		return 0;
	} );

	const handleEditClick = () => {
		//
		navigate( '/collections/' + event.collection_id + '/events/' + event._id );
	};



	const timeAgo = () => {
		//
		const duration = ( new Date() - new Date( event.lastUpdate ) );

		return msToDurationString( { value: duration, includeSuffix: true } );
	};

	// console.log( elements );



	return ( <>
		
		<Card className='m-2 shadow event-card'>
			<Card.Body>
				<Card.Title>{( event?.title + '' ).length > NUM_CHARS_CARD_TITLE ? event.title.slice( 0, NUM_CHARS_CARD_TITLE - 1 ) + TRUNCATION_INDICATOR : event.title}</Card.Title>
				<Card.Subtitle>{'Updated ' + timeAgo()}</Card.Subtitle>


				{elements.map( ( element, index ) => {
					// console.log( element.name, index );
					return <EventElement key={element.name} event={event} element={element} />;
					// return <EventElement key={element.name} isTitle={index === 0} event={event} element={element} />;
				})}

				<TagsDisplay tagNames={event?.tags} tagsDefinition={tagsDefinition} />

				{event.createdOn ? <><span className='meta-attr label'>Created: </span>
				<DateString dateString={event.createdOn} /><br /></> : null}
				
				{event.lastUpdate ? <><span className='meta-attr label'>Last updated: </span>
				<DateString dateString={event.lastUpdate} /><br /></> : null}


				<Button onClick={handleEditClick} variant='secondary' title='Edit event'>
					<MdEdit />{' Edit'}
				</Button>
				<AdminJSON obj={event} />
			</Card.Body>
		</Card>

	</> );

};