

//



export const FONT_COLOR_LIGHT = 'rgba( 255, 255, 255, .9 )';
export const FONT_COLOR_DARK = '#111';



// export const COLOR_PRIMARY = '#3a0ca3'; // dark blue
// export const COLOR_SECONDARY = '#7209b7'; // purple
export const COLOR_PRIMARY = '#7209b7'; // purple
export const COLOR_SECONDARY = '#3a0ca3'; // dark blue
export const COLOR_ACCENT = '#f72585'; // pink
export const COLOR_ALT_PRIMARY = '#4361ee'; // light blue
export const COLOR_ALT_SECONDARY = '#4cc9f0'; // aqua


export const COLOR_PRIMARY_LIGHT = '#b984db'; // lighter purple
export const COLOR_SECONDARY_LIGHT = '#9d86d1'; // lighter dark blue
export const COLOR_ACCENT_LIGHT = '#fb88c2'; // lighter pink
export const COLOR_ALT_PRIMARY_LIGHT = '#a1b1f7'; // lighter light blue
export const COLOR_ALT_SECONDARY_LIGHT = '#a6e2f5'; // lighter aqua




export const COLOR_WHITE = '#fff';
export const COLOR_BLACK = '#000';

export const COLOR_LIGHT_GRAY = '#eee';
export const COLOR_DARK_GRAY = '222';