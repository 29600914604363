

import React, { useState, useEffect, useMemo, } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Form, Button, } from 'antd';

import { API_URL, EMAIL_RE } from '../utils';
import { InputPassword, InputText } from '../components/Input';
import { AdminJSON } from '../utils/admin';
import { BAN_CIRCLE, CHECK_CIRCLE_FILL } from '../components/icons';
// import axios from 'axios';



export default function Register() {

	const navigate = useNavigate();

	const [inputs, setInputs] = useState({
		email: '',
		// username: '',
		password: '',
		confirmPassword: '',
		firstName: '',
		lastName: '',
	});

	const [ emailIsValid, setEmailIsValid ] = useState( false );
	
	const [ passwordIsValid, setPasswordIsValid ] = useState( false );
	
	const [ confirmPasswordIsValid, setConfirmPasswordIsValid ] = useState( false );


	
	const handleChange = ( eName, eValue ) => setInputs( prevState => ( { ...prevState, [ eName ]: eValue } ) );

	

	const handleSubmit = () => {
		// axios.post( API_URL + '/user' );



		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(  )
		};

		fetch( API_URL + '/user', requestOptions )
			.then( response => {
				const statusCode = response.status;
				response.json().then( ( data ) => {
					if ( statusCode !== 201 ) {
						// error
						return null;
					}
	
					// save token
					if ( data.token ) {
						window.localStorage.setItem( 'token', data.token );
						// redirect to profile
						navigate( '/profile' );
					}
				} );
			} );
	};



	const validateForm = () => {
		return inputs.firstName.length > 0 
			&& inputs.lastName.length > 0 
			&& emailIsValid
			&& passwordIsValid
			&& confirmPasswordIsValid;
	};



	return ( <>
		<h1>Register</h1>

		<span>Already have an account? <Link to='/login'>Sign in</Link></span>

		<Form>
			<InputText 
				elementName='firstName' 
				elementLabel='First name' 
				value={inputs.firstName} 
				onChange={handleChange} 
			/>
			<InputText 
				elementName='lastName' 
				elementLabel='Last name' 
				value={inputs.lastName} 
				onChange={handleChange} 
			/>
			{/* <InputText elementName='username' elementLabel='Username' value={inputs.username} onChange={handleChange} /> */}
			
			<InputEmailWrapper 
				elementName='email' 
				elementLabel='Email' 
				value={inputs.email} 
				onChange={handleChange} 
				setEmailIsValid={setEmailIsValid}
				autoComplete='username'
			/>
			
			<InputPasswordWrapper 
				elementName='password' 
				elementLabel='Password' 
				value={inputs.password} 
				onChange={handleChange} 
				setPasswordIsValid={setPasswordIsValid}
				autoComplete='new-password'
			/>
			
			<InputConfirmPasswordWrapper 
				elementName='confirmPassword' 
				elementLabel='Confirm password' 
				value={inputs.confirmPassword} 
				onChange={handleChange} 
				setConfirmPasswordIsValid={setConfirmPasswordIsValid}
				compareValue={inputs.password}
				autoComplete='confirm-password'
			/>

			<Button onClick={handleSubmit} disabled={! validateForm()}>Sign up</Button>
		</Form>



		<AdminJSON obj={inputs} label={'inputs'} />

	</> );
	
}



export const InputConfirmPasswordWrapper = ( props ) => {

	const { 
		elementName, 
		elementLabel, 
		value, 
		onChange, 
		compareValue, 
		setConfirmPasswordIsValid, 
		autoComplete,
	} = props;



	const isValid = useMemo( () => {
		return compareValue === value && value.length > 0;
	}, [ value, compareValue, ] );



	useEffect( () => {
		setConfirmPasswordIsValid( isValid );
	}, [ setConfirmPasswordIsValid, isValid, ] );



	return <React.Fragment>
		<InputPassword
			elementName={elementName} 
			elementLabel={elementLabel} 
			value={value} 
			onChange={onChange} 
			autoComplete={autoComplete}
			// {...props}
		/>

		<div>
			<span className={'d-block ' + ( isValid ? 'text-success' : 'text-secondary')}>
				<span className='mx-1'>
					{isValid ? <CHECK_CIRCLE_FILL /> : <BAN_CIRCLE />}
				</span>

				{'Must match first password'}
			</span>
		</div>
	</React.Fragment>;

}



export const InputEmailWrapper = ( { elementName, elementLabel, value, onChange, setEmailIsValid, autoComplete, } ) => {

	const isValid = useMemo( () => {
		const isValid = EMAIL_RE.test( value );

		// TODO - check for existing account with that address ? 

		return isValid;
	}, [ value ] );



	useEffect( () => {
		setEmailIsValid( isValid );
	}, [ setEmailIsValid, isValid, ] );



	return <>
		<InputText 
			elementName={elementName} 
			elementLabel={elementLabel} 
			value={value} 
			onChange={onChange} 
			autoComplete={autoComplete}
			/>

		<div>
			<span className={'d-block ' + ( isValid ? 'text-success' : 'text-secondary')}>
				<span className='mx-1'>
					{isValid ? <CHECK_CIRCLE_FILL /> : <BAN_CIRCLE />}
				</span>

				{'Must be a valid email format'}
			</span>
		</div>
	</>;

}




export const InputPasswordWrapper = ( { elementName, elementLabel, value, onChange, setPasswordIsValid, autoComplete, } ) => {

	const rules = useMemo( () => [
		{
			label: 'Must be at least 8 characters',
			validate: ( value ) => {
				return value.length >= 8;
			},
		},
		{
			label: 'Must contain a lowercase letter',
			validate: ( value ) => {
				return /[a-z]/.test( value );
			},
		},
		{
			label: 'Must contain an uppercase letter',
			validate: ( value ) => {
				return /[A-Z]/.test( value );
			},
		},
		{
			label: 'Must contain a number',
			validate: ( value ) => {
				return /[0-9]/.test( value );
			},
		},
		{
			label: 'Must contain a special character',
			validate: ( value ) => {
				return /[!@#$%&.,/\\_()^*[\]{}-]/.test( value );
			},
		},
	], [] );



	useEffect( () => {

		if ( ! setPasswordIsValid ) {
			return;
		}

		let allRulesPassed = true;

		for ( let idx = 0; idx < rules.length; ++idx ) {
			if ( ! rules[ idx ].validate( value ) ) {
				allRulesPassed = false;
				break;
			}
		}
		
		setPasswordIsValid( allRulesPassed );
		
	}, [ value, rules, setPasswordIsValid, ] );

	

	return <>
			<InputPassword 
				elementName={elementName} 
				elementLabel={elementLabel} 
				value={value} 
				onChange={onChange} 
				autoComplete={autoComplete}
				/>
			
			<div>
				{rules.map( rule => {
					const isValid = rule.validate( value );
					
					return <span 
						key={rule.label}
						className={'d-block ' + ( isValid ? 'text-success' : 'text-secondary' )} 
					>
						<span className='mx-1'>
							{isValid ? <CHECK_CIRCLE_FILL /> : <BAN_CIRCLE />}
						</span>

						{rule.label}
					</span>
				} )}
			</div>
			
	</>;
	
}