

import { useEffect, useState } from 'react';

// import Button from 'react-bootstrap/Button';

import { Button, DatePicker, ColorPicker, Input, Select, Switch, InputNumber, Rate, Skeleton, } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import axios from 'axios';
import dayjs from 'dayjs';

import { getUserId, API_USERS_URL } from '../utils';
import _ from 'lodash';
import { FONT_COLOR_DARK } from '../utils/colors';



//////////////////////////////////////////////////
//   
//   WRAPPER
//   
//////////////////////////////////////////////////

const Wrapper = ( { children, elementLabel, elementName, labelInline = false, showSkeleton = false, } ) => {
	
	//



	return <>
		<div 
			className={'' + ( labelInline ? 'd-flex' : 'mb-2' )}
			style={{
				// border: '1px solid ' + COLOR_LIGHT_GRAY,
				// backgroundColor: COLOR_LIGHT_GRAY,
			}}
		>
			<Skeleton loading={showSkeleton} active>
				<label 
					htmlFor={'input-' + elementName}
					className={labelInline ? 'my-auto' : 'd-block' } 
					// style={labelStyle}
					style={{ minWidth: 'fit-content', height: 'fit-content' }}
				>
					{elementLabel}
					{labelInline ? <>&nbsp;</> : <></>}
				</label>
				{children}
			</Skeleton>
		</div>
	</>;


	// return <>
	// 	<div className={'' + ( labelInline ? 'd-flex' : 'mb-2' )}>
	// 		<label 
	// 			htmlFor={'input-' + elementName}
	// 			className={labelInline ? 'my-auto' : 'd-block' } 
	// 			// style={labelStyle}
	// 			style={{ minWidth: 'fit-content', height: 'fit-content' }}
	// 		>
	// 			{elementLabel}
	// 			{labelInline ? <>&nbsp;</> : <></>}
	// 		</label>
	// 		{children}
	// 	</div>
	// </>;

}



//////////////////////////////////////////////////
//   
//   TEXT
//   
//////////////////////////////////////////////////

export function InputText ( props ) {
	
	const { 
		elementName, 
		// elementLabel, 
		value, 
		onChange, 
		disabled = false, 
		required = false,
		placeholder = null,
		autoCapitalize = 'sentences', /* none|words|characters */ 
		autoComplete,
		// showSkeleton,
	} = props;

	const handleChange = e => onChange( e.target.name, e.target.value );
	


	return <>
		<Wrapper 
			{...props}
			// elementName={elementName} 
			// elementLabel={elementLabel}
			// showSkeleton={showSkeleton}
		>
			<Input 
				type='text'
				name={elementName}
				id={'input-' + elementName}
				// addonBefore={elementLabel}
				size='large'
				className=''
				placeholder={placeholder}
				autoCapitalize={autoCapitalize}
				autoComplete={autoComplete}
				disabled={disabled}
				required={required}
				value={value} 
				onChange={handleChange} />
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   PASSWORD
//   
//////////////////////////////////////////////////

export function InputPassword ( { elementName, elementLabel, value, onChange, 
	disabled = false, 
	required = false,
	placeholder = null,
	autoComplete,
 } ) {
		
		const handleChange = e => onChange( e.target.name, e.target.value );
		
		return <>
		<Wrapper 
			elementName={elementName} 
			elementLabel={elementLabel}>
			<Input.Password
				type='text'
				name={elementName}
				id={'input-' + elementName}
				size='large'
				className=''
				placeholder={placeholder}
				autoComplete={autoComplete}
				disabled={disabled}
				required={required}
				value={value} 
				onChange={handleChange} />
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   TEXTAREA
//   
//////////////////////////////////////////////////

export function InputTextArea ( props ) {
	
	const { 
		elementName, 
		// elementLabel, 
		value, 
		onChange, 
		disabled = false, 
		required = false,
		autoCapitalize = 'sentences', /* none|words|characters */ 
		placeholder = null, 
	} = props;

	const handleChange = e => onChange( e.target.name, e.target.value );
	


	return <>
		<Wrapper 
			{...props}
			// elementName={elementName} 
			// elementLabel={elementLabel}
		>
			<TextArea 
				name={elementName}
				id={'input-' + elementName}
				size='large'
				placeholder={placeholder}
				autoCapitalize={autoCapitalize}
				disabled={disabled}
				required={required}
				value={value}
				onChange={handleChange} 
				autoSize={{ minRows: 2, maxRows: 8, }}
			/>
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   NUMBER
//   
//////////////////////////////////////////////////

export function InputNum ( { elementName, elementLabel, value, onChange, 
	disabled = false, } ) {
		
	const handleChange = value => { 
		onChange( elementName, value );
	};
	
	return <>
		<Wrapper 
			elementName={elementName}
			elementLabel={elementLabel}>
			<InputNumber
				name={elementName}
				id={'input-' + elementName}
				size='large'
				className='w-100'
				disabled={disabled}
				value={value}
				onChange={handleChange} 
				controls={true}
				/>
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   SWITCH
//   
//////////////////////////////////////////////////

export function InputSwitch ( { elementName, elementLabel, value, onChange, 
	disabled = false, } ) {
		
	const handleChange = checked => { 
		// console.log( 'input: before: ', checked );
		onChange( elementName, checked );
	};
	
	return <>
		<Wrapper 
			elementName={elementName}
			elementLabel={elementLabel}>
			<Switch
				name={elementName}
				id={'input-' + elementName}
				size='large'
				disabled={disabled}
				checked={value}
				onChange={handleChange} 
				/>
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   SELECT
//   
//////////////////////////////////////////////////

export function InputSelect ( { elementName, elementLabel, value, onChange, 
	disabled = false, 
	required = false,
	placeholder = null,
	options = [],
	showSearch = true,
	allowClear = true,
	labelInline = false,
	selectIcons = false,
} ) {
		
	// const filterFunction = ( typeof filterOption === 'function' ? filterOption : ( input, option ) => {
	// 	return ( option?.label ?? '' ).toLowerCase().includes( input.toLowerCase() );
	// } );
		
	const handleChange = value => onChange( elementName, value );



	const MAX_SELECT_ICONS = 6;

	const showSelectIcons = !! selectIcons && options.length <= MAX_SELECT_ICONS;

	const displayType = showSelectIcons ? 'selectIcons' : 'select';



	// Select Icons
	const DEFAULT_BACKGROUND_COLOR = '#eee';
	const DEFAULT_BORDER_COLOR = '#ccc';
	const DEFAULT_BACKGROUND_COLOR_SELECTED = '#bbb';
	const DEFAULT_BORDER_COLOR_SELECTED = '#111';
	


	return <>
		<Wrapper 
			elementName={elementName}
			elementLabel={elementLabel}
			labelInline={labelInline}>

			{displayType === 'select' ? <Select
				name={elementName}
				id={'input-' + elementName}
				size='large'
				className={'w-100 ' + ( labelInline ? 'my-auto' : '' )}
				style={{minWidth: '180px'}}
				placeholder={placeholder}
				disabled={disabled}
				required={required}
				value={value}
				onChange={handleChange}
				options={options}
				allowClear={allowClear} 
				// filterOption={filterOption ? filterFunction : false}
				// filterOption={true}
				showSearch={showSearch}
			/> : null}

			{displayType === 'selectIcons' ? <>
			<div 
				className='d-flex justify-content-start '
				// className='d-flex justify-content-between justify-content-md-start'
				// style={{height: '2em', width: '2em',}}
			>
				{options.map( choice => <div className='d-inline'>
					<Button 
						className='m-1' 
						style={{
							backgroundColor: ( value === choice.value ? choice?.color || DEFAULT_BACKGROUND_COLOR_SELECTED : DEFAULT_BACKGROUND_COLOR ), 
							border: '3px solid ' + ( choice?.color ? choice?.color : ( value === choice.value ? DEFAULT_BORDER_COLOR_SELECTED : DEFAULT_BORDER_COLOR ) ),
							// border: '3px solid ' + choice?.color || '#ccc',
							height: '3em',
							minWidth: '3em',
							color: FONT_COLOR_DARK,
						}} 
						onClick={() => choice.value === value ? handleChange( null ) : handleChange( choice.value )}
					>
						{choice?.iconJSX}
						{choice.label}
					</Button>
				</div> )}
			</div>
			</> : null}

		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   RATING
//   
//////////////////////////////////////////////////

export function InputRating ( { elementName, elementLabel, value, onChange, 
	disabled = false, 
	required = false,
	} ) {
		
	const handleChange = ( value ) => {
		onChange( elementName, value );
	};
	
	return <>
		<Wrapper 
			elementName={elementName}
			elementLabel={elementLabel}>
			<Rate
				name={elementName}
				id={'input-' + elementName}
				size='large'
				value={value}
				onChange={handleChange} 
				disabled={disabled}
				required={required}
				/>
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   COLOR
//   
//////////////////////////////////////////////////

export function InputColor ( { elementName, elementLabel, value = '#ffffff', onChange, 
	disabled = false, 
	required = false,
	fi = false, } ) {
		
	const handleChange = ( value, hex ) => {
		onChange( elementName, hex );
	};
	
	return <>
		<Wrapper 
			elementName={elementName}
			elementLabel={elementLabel}>
			<ColorPicker
				name={elementName}
				id={'input-' + elementName}
				size='large'
				value={value}
				onChange={handleChange} 
				disabled={disabled}
				required={required}
				/>
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   DATE
//   
//////////////////////////////////////////////////

export function InputDate ( { elementName, elementLabel, value, onChange, 
	disabled = false, 
	required = false,
	defaultToday = true, 
	showTime = false, // include time in date picker (date/time)
} ) {

	

	const handleChange = ( date, dateString ) => {
		onChange( elementName, (date && dayjs( date ).isValid() ? dayjs( date, dateFormat() ).toISOString() : null ) );
	};



	let showTimeObj = _.cloneDeep( showTime );
	if ( showTimeObj === true ) {
		showTimeObj = {};
	}
	if ( typeof showTimeObj === 'object' ) {
		showTimeObj.format = showTimeObj.format || 'h:mm a';
		// showTimeObj.changeOnScroll = true;
		showTimeObj.use12Hours = true;
		showTimeObj.minuteStep = 5;
	}



	const dateFormat = () => {
		// return {};
		return 'YYYY-MM-DD' + ( showTime ? ' h:mm a' : '' );
	};



	const isTouchScreenDevice = () =>{
		try {
			document.createEvent( 'TouchEvent' );
			return true;
		}
		catch ( e ) {
			return false;
		}
	};



	const presets = [];
	
	if ( showTime ) {
		presets.push( {
			label: '-2h',
			value: dayjs().add( -2, 'h' )
		} );
		presets.push( {
			label: '-1h',
			value: dayjs().add( -1, 'h' )
		} );
		presets.push( {
			label: '-15m',
			value: dayjs().add( -15, 'm' )
		} );
		presets.push( {
			label: '-5m',
			value: dayjs().add( -5, 'm' )
		} );
		presets.push( {
			label: 'Now',
			value: dayjs(),
			displayStyle: 'primary',
		} );
		presets.push( {
			label: '+5m',
			value: dayjs().add( 5, 'm' )
		} );
	}
	else {
		presets.push( {
			label: 'Yesterday',
			value: dayjs().add( -1, 'd' )
		} );
		presets.push( {
			label: 'Today',
			value: dayjs(),
			displayStyle: 'primary',
		} );
		presets.push( {
			label: 'Tomorrow',
			value: dayjs().add( 1, 'd' )
		} );
	}



	const FooterButtons = () => {
		//

		return ( <>
			<div className='d-flex'>
				{presets.map( p => <Button 
					className='p-1 m-1'
					type={p?.displayStyle === 'primary' ? '' : 'text'}
					onClick={() => updateFromButton( p.value )}
				>
					{p.label}
					</Button> )}
			</div>
		</> );
	};

	const updateFromButton = ( value ) => {
		onChange( elementName, dayjs( value ).toISOString() );
	};


	
	return <>
		<Wrapper 
			elementName={elementName}
			elementLabel={elementLabel}>
			<DatePicker 
				name={'input-' + elementName}
				size='large'
				className='form-control'
				// defaultValue={dayjs( '2023-03-03' )}
				// value={dayjs( value )}
				value={value && dayjs( value ).isValid() ? dayjs( value ) : null} // default to now if value is null or empty string
				onChange={handleChange}
				disabled={disabled}
				required={required} 
				showTime={showTimeObj}
				showNow={false}
				format={dateFormat()}
				inputReadOnly={isTouchScreenDevice()}
				needConfirm={false}
				// presets={presets}
				renderExtraFooter={FooterButtons}
				/>
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   USER - SINGLE
//   
//////////////////////////////////////////////////

export function InputUser ( { elementName, elementLabel, value, onChange, 
	disabled = false, 
	required = false,
	showAddMe = true, } ) {
		
		
		
		const [ users, setUsers ] = useState( [] );
		
		
		
		useEffect( () => {
			
			axios.get( API_USERS_URL, {
				headers: { 
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-type': 'application/json',
				}
			} ).then( data => {
				if ( data.status !== 200 ) {
					console.error( 'bad user data from server' );
					
					return;
				}
				
				setUsers( Array.from( data.data, e => { return { value: e._id, label: e.fullName }; } ) );
			} ).catch( error => {
				if ( error?.response?.status === 401 ) {
					// navigate( '/login?isRedirect=true' );
					console.error( '401 getting users from server' );
				}
				else {
					console.log( JSON.stringify( error, null, '\t' ) );
				}
			} );
			
		}, [  ] );
		

		
	const handleChange = v => onChange( elementName, v );

	const handleClickAddMe = () => onChange( elementName, getUserId() );
	
	return <>
		<Wrapper 
			elementName={elementName}
			elementLabel={elementLabel}>
			<div className='d-flex align-middle'>
				<Select 
					name={elementName}
					id={'input-' + elementName}
					size='large'
					className=''
					style={{flexGrow: 1}}
					placeholder={'Search...'}
					value={value} 
					onChange={handleChange} 
					options={users}
					disabled={disabled}
					mode=''
					optionFilterProp='label'
					/>

				{showAddMe ? <Button style={{whiteSpace: 'nowrap', marginLeft: '.5em',}} disabled={value.indexOf( getUserId() ) > -1} onClick={handleClickAddMe}>{'+ Me'}</Button> : null}
			</div>
		</Wrapper>
	</>;

}



//////////////////////////////////////////////////
//   
//   USER - MANY
//   
//////////////////////////////////////////////////

export function InputUsers ( { elementName, elementLabel, value, onChange, 
	disabled = false, 
	required = false,
	showAddMe = true,
	singleSelect = false, } ) {
		
	// TODO - handle large volume (search, pagination, etc)

	// TODO - fix onChange handleing for single select
	
	
	
	const [ users, setUsers ] = useState( [] );
	
	
	
	useEffect( () => {
		
		axios.get( API_USERS_URL, {
			headers: { 
				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
				'Content-type': 'application/json',
			}
		} ).then( data => {
			if ( data.status !== 200 ) {
				console.error( 'bad user data from server' );
				
				return;
			}
			
			setUsers( Array.from( data.data, e => { return { value: e._id, label: e.fullName }; } ) );
		} ).catch( error => {
			if ( error?.response?.status === 401 ) {
				// navigate( '/login?isRedirect=true' );
				console.error( '401 getting users from server' );
			}
			else {
				console.log( JSON.stringify( error, null, '\t' ) );
			}
		} );
		
	}, [  ] );
	

	
	const handleChange = v => { 
		onChange( elementName, v );
	};
	
	const handleClickAddMe = () => {
		onChange( elementName, [ ...value, getUserId() ] );
	};
	

	
	return <>
		<Wrapper 
			elementName={elementName}
			elementLabel={elementLabel}>
			<div className='d-flex align-middle'>
				<Select 
					name={elementName}
					id={'input-' + elementName}
					size='large'
					className=''
					style={{flexGrow: 1}}
					placeholder={'Search...'}
					value={value} 
					onChange={handleChange} 
					options={users}
					disabled={disabled}
					mode='multiple'
					optionFilterProp='label'
					/>
			
				{showAddMe ? <Button style={{whiteSpace: 'nowrap', marginLeft: '.5em',}} disabled={value.indexOf( getUserId() ) > -1} onClick={handleClickAddMe}>{'+ Me'}</Button> : null}
			</div>
		</Wrapper>
	</>;

}

// export function InputUsersOLD ( { elementName, elementLabel, value, onChange, 
// 	disabled = false, 
// 	required = false,
// 	showAddMe = true,
// 	singleSelect = false, } ) {
		
// 	// TODO - handle large volume (search, pagination, etc)

// 	// TODO - fix onChange handleing for single select
	
// 	const handleChange = selectedOption => { 
// 		setState( selectedOption );
// 		const valuesArr = Array.from( selectedOption, e => e.value );
// 		onChange( elementName, singleSelect ? valuesArr[ 0 ] : valuesArr );
// 		// onChange( elementName, Array.from( selectedOption, e => e.value ) );
// 	};
	
// 	const handleClickAddMe = () => {
// 		setState( [ ...state, { value: getUserId(), label: getFullName() } ] );
// 		onChange( elementName, [ ...Array.from( state, e => e.value ), getUserId() ] );
// 	};
	
	
	
// 	const [ users, setUsers ] = useState( [] );
	
// 	const [ state, setState ] = useState( [] );
	
// 	useEffect( () => {

// 		axios.get( hostname + '/api/users', {
// 			headers: { 
// 				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
// 				'Content-type': 'application/json',
// 			}
// 		} ).then( data => {
// 			if ( data.status !== 200 ) {
// 				console.error( 'bad user data from server' );
				
// 				return;
// 			}
			
// 			setUsers( Array.from( data.data, e => { return { value: e._id, label: e.fullName }; } ) );
// 		} ).catch( error => {
// 			if ( error.response.status === 401 ) {
// 				// navigate( '/login?isRedirect=true' );
// 				console.error( '401 getting users from server' );
// 			}
// 			else {
// 				console.log( JSON.stringify( error, null, '\t' ) );
// 			}
// 		} );
		
// 	}, [  ] );
	
// 	useEffect( () => {
// 		setState( users.filter( e => value.indexOf( e.value ) > -1 ) );
// 	}, [ users, value ] );

	
	
// 	return <>

// 		<div className='mb-2'>
// 			<label className='d-block' htmlFor={'input-' + elementName}>{elementLabel}</label>
// 			<InputGroup>
// 				{/* <InputGroup.Text id={'label-' + elementName} className='input-label'>{elementLabel}</InputGroup.Text> */}
// 				<div className='form-control react-select p-0'>
// 					<Select 
// 						name={elementName}
// 						id={'input-' + elementName}
// 						placeholder={'Search...'}
// 						value={state} 
// 						onChange={handleChange} 
// 						options={users}
// 						isDisabled={disabled}
// 						isMulti={! singleSelect}
// 						isSearchable={true} />
// 				</div>
// 				{showAddMe ? <Button disabled={value.indexOf( getUserId() ) > -1} variant='outline-secondary' onClick={handleClickAddMe}>+ Me</Button> : null}
// 			</InputGroup>
// 		</div>
// 	</>;

// }










