

import { useState } from 'react';
import { msToDurationString } from '../utils/date';
import { leadingZeros } from '../utils';

export default function DateString( { dateString } ) {

	//
	
	const date = new Date( dateString );
	
	const months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	];
	
	
	const formattedDateString = months[ date.getMonth() ] + ' ' + date.getDate() + ', ' + date.getFullYear();
	
	const fullDateTimeString = [ 
		leadingZeros( date.getFullYear(), 4 ), 
		'-', 
		leadingZeros( date.getMonth() + 1, 2 ), 
		'-', 
		leadingZeros( date.getDate(), 2 ), 
		' ', 
		leadingZeros( date.getHours(), 2 ), 
		':', 
		leadingZeros( date.getMinutes(), 2 ), 
		':', 
		leadingZeros( date.getSeconds(), 2 ), 
		'.', 
		leadingZeros( date.getMilliseconds(), 3 ), 
	].join( '' ); 

	const [ showDuration, setShowDuration ] = useState( false );


	const handleClick = () => {
		setShowDuration( ! showDuration );
	};

	return (<>
		<span className='date-string-clickable' onClick={handleClick} title={fullDateTimeString}>{showDuration ? msToDurationString( { value: new Date() - date }) : formattedDateString}</span>
	</>);

};