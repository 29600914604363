import { useState, useEffect } from 'react';

import { Form, Button, } from 'antd';

import { API_PROFILE_URL, API_USER_URL } from '../utils';
import { AdminJSON } from '../utils/admin';
import { InputText } from '../components/Input';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

export default function Profile() {

	const [data, setData] = useState({
		firstName: '',
		lastName: '',
		username: '',
		email: '',
		dob: '',
		// userId: ''
	});

	const navigate = useNavigate();



	const handleChange = ( name, value ) => setData(prevState => ({ ...prevState, [ name ]: value }));

	const handleSubmit = () => {

		axios.put( API_USER_URL + '/' + data.userId, data, {
			headers: {
				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
				// 'Content-Type': 'application/json',
			},
		} ).then( data => {
			console.log( data );
			if ( [ 200, 201 ].indexOf( data.status ) === -1 ) {
				// fail
			}
		} ).catch( error => console.log( error ) );
		
	};



	useEffect(() => {

		axios.get( API_PROFILE_URL, {
			headers: {
				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
			},
		} ).then( data => {
			if ( data.status !== 200 ) {
				return null;
			}

			setData( {
				firstName: data.data.firstName,
				lastName: data.data.lastName,
				username: data.data.username,
				email: data.data.email,
				dob: data.data.dob,
				userId: data.data._id,
			} );
		} ).catch( error => {
			if ( error.response.status === 401 ) {
				navigate( '/login?isRedirect=true' );
			}
			else {
				console.log( JSON.stringify( error, null, '\t' ) );
			}
		} );
	}, [ navigate ] );



	return (<>
		<h1 className=''>Account Settings</h1>

		<h2>{data.firstName} {data.lastName}</h2>

		<Form className='m-1'>
			<InputText elementName={'email'} elementLabel={'Email'} value={data.email} onChange={handleChange} disabled={true} />
			<InputText elementName={'firstName'} elementLabel={'First name'} value={data.firstName} onChange={handleChange} />
			<InputText elementName={'lastName'} elementLabel={'Last name'} value={data.lastName} onChange={handleChange} />
			{/* <InputText elementName={'username'} elementLabel={'Username'} value={data.username} onChange={handleChange} /> */}
			{/* <InputDate elementName={'dob'} elementLabel={'Date of birth'} value={data.dob} onChange={handleChange} /> */}
			
			<Button onClick={handleSubmit} >
				Save
			</Button>
		</Form>

		<div>
			<Link to='/account/change-password'>Change password</Link>

		</div>



		<AdminJSON obj={data} label='user data' />

	</>);

};