

//

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { App, Button, Form } from 'antd';
import axios from 'axios';

import { InputPassword } from '../components/Input';
import { InputConfirmPasswordWrapper, InputPasswordWrapper } from './Register';
import { API_CHANGE_PASSWORD, getEmail } from '../utils';



export default function AccountChangePassword() {

	//

	const { message } = App.useApp();

	const [ oldPassword, setOldPassword ] = useState( '' );
	const [ newPassword, setNewPassword ] = useState( '' );
	const [ confirmPassword, setConfirmPassword ] = useState( '' );

	const [ newPasswordIsValid, setNewPasswordIsValid ] = useState( false );
	const [ confirmPasswordIsValid, setConfirmPasswordIsValid ] = useState( false );

	const navigate = useNavigate();



	const handleSave = () => {
		axios.put( API_CHANGE_PASSWORD, {
			oldPassword: oldPassword,
			newPassword: newPassword,
		},
		{
			headers: {
				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
			}
		} ).then( data => {
			if ( data.status === 204 ) {	
				message.success( 'Your password has been updated' );

				navigate( '/account' );
			}
		}).catch( e => {
			message.error( 'Your password did not update' );
		} );
	};



	const validateForm = () => {
		return oldPassword.length > 0 
		&& newPasswordIsValid
		&& confirmPasswordIsValid;
	};



	return <>
		<h1>Change Password</h1>

		<Form className='m-1'>
			<input 
				className='d-none' 
				type='text' 
				name='username' 
				id='username' 
				autoComplete='username' 
				value={getEmail()} 
				/>
			<InputPassword 
				elementName={'oldPassword'} 
				elementLabel={'Old password'} 
				value={oldPassword} 
				onChange={( name, value ) => setOldPassword( value )} 
				autoComplete={'current-password'}
				/>
			<InputPasswordWrapper 
				elementName={'newPassword'} 
				elementLabel={'New password'} 
				value={newPassword} 
				onChange={( name, value ) => setNewPassword( value )} 
				setPasswordIsValid={setNewPasswordIsValid} 
				autoComplete={'new-password'}
				/>
			<InputConfirmPasswordWrapper
				elementName={'confirmPassword'} 
				elementLabel={'Confirm password'} 
				value={confirmPassword} 
				onChange={( name, value ) => setConfirmPassword( value )} 
				setConfirmPasswordIsValid={setConfirmPasswordIsValid} 
				compareValue={newPassword}
				autoComplete={'confirm-password'}
				/>
		
			<Button onClick={handleSave} disabled={! validateForm()}>
				Save
			</Button>
		</Form>



	</>;

}