

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import _ from 'lodash';

import { Spinner, } from 'react-bootstrap';
import { TfiSave } from 'react-icons/tfi';


import { Form, Button, Dropdown, Modal, App, Popover, Tabs, Card, Skeleton, Space, } from 'antd';
import { SortableContext, arrayMove, /*horizontalListSortingStrategy,*/ verticalListSortingStrategy } from '@dnd-kit/sortable';

import CollectionFormElement from './CollectionFormElement';
import { InputColor, InputSwitch, InputText, InputTextArea, InputUser, InputUsers } from '../Input';
import { BACK_ARROW, CLOSE, HELP_QUESTION_MARK, PLUS } from '../icons';
import './../../css/forms.css';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { API_COLLECTIONS_URL, getUserId, } from '../../utils';
import { AdminJSON } from '../../utils/admin';
import { TagsDefinition } from '../Tags';
import { dataTypes } from '../../utils/dictionary';
import { COLOR_ALT_PRIMARY, } from '../../utils/colors';



export default function CollectionForm( { collectionId } ) {

	//

	const isNewRecord = collectionId + '' === '-1';

	//
	const [ collection, setCollection ] = useState( {
		name: '',
		detail: '',
		color: '',
		ownedBy: isNewRecord ? getUserId() : '',
		allowAttachments: true,
		sharedWith: [],
		definition: {
			elements: [],
			color: '',
		},
		// tags: [],
	} );

	const [ saveStatus, setSaveStatus ] = useState( 'idle' );

	const navigate = useNavigate();

	const { message } = App.useApp();

	const { confirm } = Modal;



	const cleanPrevKeys = ( obj, forceDelete ) => {
		// TODO - temporary to fix issue with adding new choices to element

		const elements = obj.definition.elements;

		for ( var eIdx = 0; eIdx < elements.length; ++eIdx ) {
			if ( elements[ eIdx ].hasOwnProperty( 'choices' ) ) {
				for ( var cIdx = 0; cIdx < elements[ eIdx ].choices.length; ++cIdx ) {
					if ( forceDelete || 
						elements[ eIdx ].choices[ cIdx ].prevValue === '' ||
						elements[ eIdx ].choices[ cIdx ].prevValue === elements[ eIdx ].choices[ cIdx ].value ) {
						delete elements[ eIdx ].choices[ cIdx ].prevValue;
					}
				}
			}
		}

		obj.definition.elements = elements;

		return obj;
	};



	// GET COLLECTION
	useEffect( () => {

		if ( ! isNewRecord && collectionId ) {
			axios.get( API_COLLECTIONS_URL + '/' + collectionId, {
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-Type': 'application/json',
				}
			} ).then( data => {
				if ( data.status !== 200 ) {
					return;
				}
				
				// validate 'elements'
				if ( ! data.data.hasOwnProperty( 'definition' ) ) data.data.definition = {};
				if ( ! data.data.definition.hasOwnProperty( 'elements' ) ) data.data.definition.elements = [];
				
				setCollection( cleanPrevKeys( data.data, true ) );

			} ).catch( error => {
				if ( error.response.status === 401 ) {
					navigate( '/login?isRedirect=true' );
				}
				else {
					console.log( JSON.stringify( error, null, '\t' ) );
				}
			} );
		}

	}, [ collectionId, isNewRecord, navigate, ] );



	const handleSubmit = ( e ) => {

		setSaveStatus( 'saving' );

		//

		if ( ! isNewRecord ) {
			// update existing
			axios.put( API_COLLECTIONS_URL + '/' + collection._id,
				collection,
				{
					headers: {
						Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
						'Content-Type': 'application/json'
					}
				} ).then( data => {
					
					if ( data.status !== 200 ) {
						
						return;
					}

					message.success( 'Collection saved' );
					
					setSaveStatus( 'idle' );
	
				} ).catch( error => {
					setSaveStatus( 'failed' );
					message.error( 'Something went wrong. The collection may not have saved' );
				} );
		}
		else {
			// insert new
			axios.post( API_COLLECTIONS_URL,
			collection,
			{
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-Type': 'application/json',
				},
			} ).then( data => {
				// TODO - toast success
				if ( data.status !== 201 ) {
					return;
				}

				message.success( 'Collection saved' );

				setSaveStatus( 'idle' );

				const collectionId = data.data.insertedId;

				navigate( '/collections/' + collectionId + '/form' );
			} ).catch( error => {
				setSaveStatus( 'failed' );
				message.error( 'Something went wrong. The collection may not have saved.' );
			} );
		}

	};



	const handleDelete = () => {
		
		axios.delete( API_COLLECTIONS_URL + '/' + collection._id,
			{
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-Type': 'application/json'
				}
			} )
			.then( data => {
				console.log( JSON.stringify( data, null, '\t' ) );
				if ( data.status !== 200 ) {
					return;
				}

				message.success( 'Collection deleted' );

				navigate( '/collections' );
			} )
			.catch( error => {

			} );

	};



	const handleChange = ( name, value ) => setCollection(prevState => ( { ...prevState, [name]: value } ) );
	


	const menuItems = [
		{
			label: 'Delete',
			key: 'delete',
			danger: true,
			icon: <CLOSE />,
			disabled: isNewRecord,
		},
	];

	const handleMenuClick = e => {
		if ( e.key === 'delete' ) {
			confirm( {
				title: 'Confirm delete',
				icon: '',
				content: 'Deleting this collection will also delete all its items. Proceed with caution. ',
				onOk() {
					handleDelete();
				},
				onCancel() {},
			} );
		}
	};

	const menuProps = {
		items: menuItems,
		onClick: handleMenuClick,
	};

	const saveButton = <>
		<div className='d-flex'>
			<Dropdown.Button className='flex-grow-1 flex-md-grow-0' menu={menuProps} onClick={handleSubmit}>
				{saveStatus === 'idle' ? <><TfiSave /> Save</> : null}
				{saveStatus === 'saving' ? <><Spinner animation='border' role='status' size='sm' as='span'></Spinner> Saving...</> : null}
				{saveStatus === 'failed' ? <>Error</> : null}
			</Dropdown.Button>
			{/* <Button className='flex-grow-1 flex-md-grow-0' type='primary' htmlType='submit' title='Save changes'>
				{saveStatus === 'idle' ? <><TfiSave /> Save</> : null}
				{saveStatus === 'saving' ? <><Spinner animation='border' role='status' size='sm' as='span'></Spinner> Saving...</> : null}
				{saveStatus === 'failed' ? <>Error</> : null}
			</Button> */}
		</div>
	</>;



	const tabsItems = [
		{
			key: 'settings',
			label: 'Settings',
			children: <FormSettings collection={collection} handleChange={handleChange} />,
		},
		{
			key: 'card-builder',
			label: 'Card Builder',
			children: <FormCards collection={collection} setCollection={setCollection} />,
		},
		{
			key: 'tags',
			label: 'Tags',
			children: <FormTags collection={collection} setCollection={setCollection} />,
		},
	];



	return (<>
	
		{isNewRecord ? null	: <div>
			<Link to={'/collections/' + collectionId + '/events'}>
				<BACK_ARROW /> back to collection
			</Link>
		</div>}



		<h1>Collection Settings</h1>

		<Form className=' m-1 ' autoComplete='off'>
			<Tabs
				defaultActiveKey='settings'
				items={tabsItems}
				onChange={key => {}}
			>

			</Tabs>

			{saveButton}
		</Form>



		<AdminJSON obj={collection} />

	</>);

};



const FormSettings = ( props ) => {

	const { collection, handleChange } = props;

	return <>
		<div className='form-bundle'>
					
			<InputText elementName='name' elementLabel='Name' value={collection.name} onChange={handleChange} showSkeleton={false} />
			<InputTextArea fi={true} elementName='detail' elementLabel='Detail' value={collection.detail} onChange={handleChange} />
			<InputUser fi={true} elementName='ownedBy' elementLabel='Owned by' value={collection.ownedBy} onChange={handleChange} />
			<InputUsers fi={true} elementName='sharedWith' elementLabel='Shared with' value={collection.sharedWith} onChange={handleChange} />
			<InputColor fi={true} elementName='color' elementLabel='Color' value={collection.color} onChange={handleChange} />
			<InputSwitch elementName='allowAttachments' elementLabel='Allow attachments' value={collection.allowAttachments} onChange={handleChange} />

			{/* cover image */}
			{/* {saveButton} */}

		</div>
	</>;

};



const FormCards = ( props ) => {

	const { collection, setCollection, } = props;

	// const [ titleElements, setTitleElements ] = useState( collection.definition.titleElements || ['one','two','three',] );

	const elementHelpContent = <>
		<span>
			Elements make your collections more meaningful by specifying the 
			types of information in each item. These influence how filters 
			work and how data is displayed. 
		</span>
	</>;



	const handleFEChange = ( elementName, propName, value ) => {
			
		setCollection( prevState => {
			const elements = prevState.definition.elements;
			let foundMatch = false;
			for ( let i = 0; i < elements.length; ++i ) {
				if ( elements[ i ].name === elementName ) {
					foundMatch = true;
					
					// PROP - name
					if ( propName === 'name' ) { // renaming element definition
						if ( ! elements[ i ].prevName ) {
							// preserve previous key before renaming
							elements[ i ].prevName = elementName;
						}
						else {
							if ( value === elements[ i ].prevName ) {
								// back to original, delete prevName prop
								delete elements[ i ].prevName;
							}
						}
					}

					// PROP - labelSingular, labelPlural
					else if ( propName === 'labelSingular' || propName === 'labelPlural' ) {
						if ( ! elements[ i ].label ) elements[ i ].label = {};
						if ( propName === 'labelSingular' ) {
							elements[ i ].label.singular = value;
							break;
						}
						if ( propName === 'labelPlural' ) {
							elements[ i ].label.plural = value;
							break;
						}
					}

					// PROP - choices 
					else if ( propName === 'choices' ) {
						//
						_.set( elements[ i ], 'options.choices', value );
					}

					// ELSE - update prop
					elements[ i ][ propName ] = value;
					break;
				}
			}

			// no match found, add new element to array
			if ( ! foundMatch ) {
				// elements.push( value );
			}

			return cleanPrevKeys( { ...prevState, definition: { ...prevState.definition, elements: elements } } );
		} );
		
	};

	const cleanPrevKeys = ( obj, forceDelete ) => {
		// TODO - temporary to fix issue with adding new choices to element

		const elements = obj.definition.elements;

		for ( var eIdx = 0; eIdx < elements.length; ++eIdx ) {
			if ( elements[ eIdx ].hasOwnProperty( 'choices' ) ) {
				for ( var cIdx = 0; cIdx < elements[ eIdx ].choices.length; ++cIdx ) {
					if ( forceDelete || 
						elements[ eIdx ].choices[ cIdx ].prevValue === '' ||
						elements[ eIdx ].choices[ cIdx ].prevValue === elements[ eIdx ].choices[ cIdx ].value ) {
						delete elements[ eIdx ].choices[ cIdx ].prevValue;
					}
				}
			}
		}

		obj.definition.elements = elements;

		return obj;
	};

	const handleNewElement = () => {
		
		setCollection( { ...collection, definition: { ...collection.definition, elements: [ ...collection.definition.elements, {
			name: '',
			label: {
				singular: '',
				plural: ''
			},
			options: {
				choices: []
			}
		} ] } } );

	};

	const handleDeleteElement = ( elementName ) => {
		setCollection( prevState => ( { ...prevState, definition: { ...prevState.definition, elements: prevState.definition.elements.filter( e => e.name !== elementName ) } } ) );
	};

	const handleDragEnd = ( event ) => {
		//
		const { active, over, } = event;

		if ( active.id !== over.id ) {
			setCollection( prevState => {
				const elements = prevState.definition.elements;
				const activeIdx = elements.map( e => e.label.singular ).indexOf( active.id );
				const overIdx = elements.map( e => e.label.singular ).indexOf( over.id );
				
				return {
					...prevState,
					definition: {
						...prevState.definition,
						elements: arrayMove( elements, activeIdx, overIdx )
					}
				};
			} );
		}

	};



	return <>
		<div className='form-bundle'>

			<h4>Card Preview</h4>

			<Card style={{width: '18em',backgroundColor: '#eee', borderColor: COLOR_ALT_PRIMARY,}}>
				<span>{collection?.name}</span>

				{collection.definition.elements.map( element => {
					//

					return ( <Space key={element.name}>
						{dataTypes.find( e => e.name === element.dataType )?.bsIconJSX}
						<Skeleton.Input active={false} size='small' className='m-1' />
					</Space> );
				} )}
			</Card>



			<div className='d-flex'>
				<h4 style={{flexGrow: 1,}}>
					Elements
					<span className='mx-2' style={{fontSize: '70%',}}>
						({collection?.definition?.elements?.length})
					</span>
				</h4>

				<Popover content={elementHelpContent} trigger='click'>
					<span style={{fontSize: '150%',}}><HELP_QUESTION_MARK /></span>
				</Popover>
			</div>



			<DndContext
				collisionDetection={closestCenter}
				// onDragEnd={handleDragEnd}
				// using onDragOver to prevent 'bounce back' animation on drop (viewed in Brave on mobile mode)
				onDragOver={handleDragEnd}
			>
				<SortableContext
					items={collection.definition.elements.map( e => e.label.singular )}
					strategy={verticalListSortingStrategy}
				>
					{collection.definition.elements.map( ( element, idx ) => <CollectionFormElement
						key={idx} 
						idx={idx}
						id={element.label.singular}
						element={element} 
						value={element} 
						onChange={handleFEChange} 
						onDelete={handleDeleteElement} 
					/> )}
				</SortableContext>
			</DndContext>


			<div className='d-flex'>
				<Button className='flex-grow-1 flex-md-grow-0' type='primary' title='Add new element' onClick={handleNewElement}>
					<PLUS /> New element
				</Button>
			</div>



			{/* <h4>Title</h4> */}
			{/* <DndContext
				collisionDetection={closestCenter}
				onDragOver={() => {}}
			>
				<SortableContext
					items={titleElements.map( e => e )}
					strategy={horizontalListSortingStrategy}
				>
					{titleElements.map( e => <span key={e}>{e}</span> )}
				</SortableContext>
			</DndContext> */}
				
		</div>
	</>;

};



const FormTags = ( props ) => {

	const { collection, setCollection, } = props;

	const tagsHelpContent = <>
		<span>
			Tags offer a powerful means of filtering your data. In contrast to 
			elements, tags are extremely flexible and provide a simple, 
			intuitive interface.
		</span>
	</>;



	const handleTagsChange = newTags => {
		setCollection( prevState => ( { ...prevState, definition: { ...prevState.definition, tags: newTags } } ) );
	};



	return <>
		<div className='form-bundle'>
			<div className='d-flex'>
				<h4 style={{flexGrow: 1,}}>Tags</h4>

				<Popover content={tagsHelpContent} trigger='click'>
					<span style={{fontSize: '150%',}}><HELP_QUESTION_MARK /></span>
				</Popover>
			</div>

			<TagsDefinition 
				value={collection.definition?.tags} 
				onChange={handleTagsChange} 
			/>
		</div>
	</>;

};