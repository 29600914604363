



export default function Utils() {
	
};


//////////////////////////////////////////////////
//////////////////////////////////////////////////
////   
////   USER / AUTH / TOKEN
////   
//////////////////////////////////////////////////
//////////////////////////////////////////////////

const getTokenObj = () => {

	// returns the decoded payload of the token as an object
	// NOTE: does NOT verify token is still valid - Not to be used for security, only for accessing the payload

	const token = window.localStorage.getItem( 'token' );

	if ( token && ( token + '' ).split( '.' ).length > 1 ) {
		return JSON.parse( atob( ( token + '' ).split( '.' )[ 1 ] ) ); // TODO - Buffer.from() ? other? atob is deprecated...
	}

	return null;

}

export function isTokenValid() {

	return getTokenExpiry( { relative: true } ) > 0;

}

export function getTokenExpiry( { relative = false } = {} ) {

	// console.log( getTokenObj() );

	const exp = getTokenObj()?.exp;

	return ( relative ? secondsFromNow( exp ) : exp );

}

export function getTokenIssuedAt( { relative = false } = {} ) {

	return getTokenObj()?.iat;

}

export function getUserId() {

	return getTokenObj()?.userId + '';

}

export function getEmail() {

	return getTokenObj()?.email + '';

}

export function getFullName() {

	return getTokenObj()?.fullName + '';

}

export function getFirstName() {

	return getTokenObj()?.firstName + '';

}

export function getLastName() {

	return getTokenObj()?.lastName + '';

}

export function userRoles() {

	return getTokenObj()?.roles || [];

}

export const userIsAdmin = userRoles().indexOf( 'admin' ) > -1;


//////////////////////////////////////////////////
//////////////////////////////////////////////////
////   
////   STATICS
////   
//////////////////////////////////////////////////
//////////////////////////////////////////////////


// export const hostname = 'http://localhost:3000';
export const HOSTNAME = process.env.REACT_APP_API_SERVER; // 'http://10.0.1.181:3000'
const ABSOLUTE_HOST = true;
export const API_URL = ( ABSOLUTE_HOST ? HOSTNAME : '' ) + '/api';
export const API_COLLECTIONS_URL = API_URL + '/collections';
export const API_EVENTS_URL = API_URL + '/events';
export const API_USERS_URL = API_URL + '/users';
export const API_USER_URL = API_URL + '/user';
export const API_LOGIN_URL = API_URL + '/login';
export const API_PROFILE_URL = API_URL + '/user-info';
export const API_CHANGE_PASSWORD = API_URL + '/change-password';

export const EMAIL_RE = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;


//////////////////////////////////////////////////
//////////////////////////////////////////////////
////   
////   UTILITIES
////   
//////////////////////////////////////////////////
//////////////////////////////////////////////////


const secondsFromNow = sec => { 
	if ( sec === undefined || sec === null ) {
		return null;
	}
	// TODO - consolidate to date.js

	return parseInt( sec - parseInt( Date.now() / 1000, 10 ), 10 ); 
};



export function leadingZeros( value, numPlaces ) {
	while ( ( value + '' ).length < numPlaces ) {
		value = '0' + value;
	}

	return value;
}

export function deepEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if (
			( areObjects && !deepEqual(val1, val2) ) ||
			( !areObjects && val1 !== val2 )
		) {
			return false;
		}
	}

	return true;
}

function isObject(object) {
	return object != null && typeof object === 'object';
}

