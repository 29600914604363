// import { Button } from "antd";
// import _ from "lodash";
// import { CHEVRON_RIGHT_COMPACT } from "./components/icons";
// import { useState } from "react";

// import { DndContext, closestCenter } from "@dnd-kit/core";
// import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";
// import { useState } from "react";
// import { DRAG_HANDLE } from "./components/icons";






export default function Temp() {

	//


	return <ColorPallete />;

	// return <DnD />;

}



// const DnD = () => {

// 	//

// 	// const [ arr, setArr ] = useState( [ 'one', 'two', 'three' ] );
// 	const [ arr, setArr ] = useState( [
// 		{
// 			label: 'one -',
// 		},
// 		{
// 			label: 'two -',
// 		},
// 		{
// 			label: 'three -',
// 		},
// 	] );

// 	const handleDragEnd = event => {
// 		const { active, over, } = event;
// 		console.log( 'ACTIVE: ' + active.id );
// 		console.log( 'OVER: ' + over.id );

// 		if ( active.id !== over.id ) {
// 			setArr( items => {
// 				const activeIdx = items.map( e => e.label ).indexOf( active.id );
// 				const overIdx = items.map( e => e.label ).indexOf( over.id );

// 				return arrayMove( items, activeIdx, overIdx );
				
// 			} );
// 		}
// 	};



// 	return <>
// 		<h1>Drag n Drop Demo</h1>

// 		<DndContext
// 			collisionDetection={closestCenter}
// 			onDragEnd={handleDragEnd}
// 		>
// 			<SortableContext
// 				items={arr.map( element => element.label )}
// 				strategy={verticalListSortingStrategy}
// 			>
// 				{arr.map( element => <SortableItem key={element.label} id={element.label} /> )}
// 			</SortableContext>
// 		</DndContext>
// 	</>;
// }



// const SortableItem = props => {

// 	//

// 	const {
// 		attributes,
// 		listeners,
// 		setNodeRef,
// 		setActivatorNodeRef,
// 		transform,
// 		transition,
// 	} = useSortable( { id: props.id } );

// 	const style = {
// 		transform: CSS.Transform.toString( transform ),
// 		transition,
// 		touchAction: 'none',
// 	};



// 	return <>
// 		<div ref={setNodeRef} style={style} >
// 			{props.id}
// 			{' - - - - - - - - '}
// 			<DRAG_HANDLE ref={setActivatorNodeRef} {...attributes} {...listeners} />
// 		</div>
// 	</>;
// };



const ColorPallete = () => {

	const colorPalette = {
		primary: {
			default: '#f72585',
		},
		secondary: {
			default: '#7209b7',
		},
		tertiary: {
			default: '#3a0ca3',
		},
		quaternary: {
			default: '#4361ee',
		},
		quinary: {
			default: '#4cc9f0',
		},
	};

	const fontColors = [
		'#111',
		'#eee',
		'#fff',
		'rgba( 255, 255, 255, .9 )',
		'rgba( 255, 255, 255, .8 )',
	];


	
	return (<>

		<h1>Temp</h1>

		{Object.keys( colorPalette ).map( ( key => <>
			<div 
				className='p-2 m-2'
				style={{backgroundColor: colorPalette[ key ].default,
					width: '300px',
					height: '180px',
					fontWeight: 'bold',}}
			>
				<h3>{colorPalette[ key ].default}</h3>
				{fontColors.map( color => <><span style={{color: color}}>{key} - {color}</span><br /></> )}
			</div>
		</> ) )}
		
	</>);

}