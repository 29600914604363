import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CollectionList from '../components/collections/CollectionList';
import axios from 'axios';
import { AiOutlinePlus } from 'react-icons/ai';
import Button from 'react-bootstrap/esm/Button';

import { API_COLLECTIONS_URL, getUserId, } from '../utils';
import { AdminJSON } from '../utils/admin';



export default function Collections() {

	const [ items, setItems ] = useState( [] );

	const [ showPlaceholder, setShowPlaceholder ] = useState( ! items.length )

	const navigate = useNavigate();

	// axios.interceptors.request.use( config => {
	// 	console.log( config );

	// 	return config;
	// } );

	useEffect( () => {
		
		// console.log( 'axios request :: GET ' + API_COLLECTIONS_URL );

		axios.get( API_COLLECTIONS_URL, {
			headers: {
				Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
				'Content-Type': 'application/json',
			}
		} ).then ( data => {
			if ( data.status !== 200 ) {
				return;
			}

			setItems( data.data );
			setShowPlaceholder( false );
		} ).catch ( error => {
			//
			if ( error.response.status === 401 ) {
				navigate( '/login?isRedirect=true', { state: { isRedirect: true, returnPath: '/collections', } } );
			}
			else {
				console.log( JSON.stringify( error, null, '\t' ) );
			}
		} );

	}, [ navigate ] );



	const handleNewCollection = () => {
		navigate( '/collections/-1/form' );
	};



	const favCollections = items.filter( item => item?.layoutTags?.indexOf( 'favorite' ) > -1 );

	const myCollections = items.filter( item => item.ownedBy === getUserId() );

	const sharedCollections = items.filter( item => item?.sharedWith?.indexOf( getUserId() ) > -1 && item?.ownedBy !== getUserId() );



	return ( <>
		
		<h1>All collections 
			<Button variant='light' onClick={handleNewCollection} title='New collection'>
				<AiOutlinePlus />
			</Button>
		</h1>



		<h2>Favorites
			<span style={{ fontSize: '60%', marginLeft: '.5rem' }}>
				(
				{favCollections.length}
				)
			</span>
		</h2>

		<CollectionList data={favCollections} showPlaceholder={showPlaceholder} />



		<h2>My collections
			<span style={{ fontSize: '60%', marginLeft: '.5rem' }}>
				(
				{myCollections.length}
				)
			</span>
		</h2>

		<CollectionList data={myCollections} showPlaceholder={showPlaceholder} />



		<h2>Shared with me
			<span style={{ fontSize: '60%', marginLeft: '.5rem' }}>
				(
				{sharedCollections.length}
				)
			</span>
		</h2>
		
		<CollectionList data={sharedCollections} showPlaceholder={showPlaceholder} />



		<AdminJSON obj={items} label={'items'} />
	</> );

};