

import React, { useState } from 'react';

import { Form, } from 'antd';
import Button from 'react-bootstrap/Button';

import { Link, useLocation, useNavigate, } from 'react-router-dom';
import axios from 'axios';

import { API_LOGIN_URL, } from '../utils';
import { InputPassword, InputText } from '../components/Input';



const Login = ( props ) => {

	// 

	return <>
		<h1>Login</h1>

		<LoginWidget {...props} />
	</>
}

export default Login;



export function LoginWidget ( props ) {
	
	const navigate = useNavigate();

	const location = useLocation();

	
	
	const [ username, setUsername ] = useState( '' );
	const [ password, setPassword ] = useState( '' );
	const [ showMessage400, setShowMessage400 ] = useState( false );

	

	const isRedirect = location.state?.isRedirect;



	const handleSubmit = (  ) => {

		axios.post( API_LOGIN_URL, {
			username: ( username + '' ).toLowerCase(),
			password: password
		}, {
			headers: { 
				'Content-Type': 'application/json',
			}
		} ).then( data => {
			if ( ! data.data.token ) {
				// not auth'd
				console.log( 'no auth' );

				return;
			}

			window.localStorage.setItem( 'token', data.data.token );

			if ( isRedirect ) {
				navigate( location.state?.returnPath || -1 );
			}
			else {
				navigate( '/collections' );
			}
		} ).catch( error => {
			if ( error.response.status === 400 ) {
				setShowMessage400( true );
			}
		} );

	};



	const validateForm = () => {
		return username.length > 0 && password.length > 0;
	};


	
	return (
		<>
			<div className='login-container'>
				<Form className='m-1' >

					<InputText 
						elementName='username' 
						elementLabel='User ID' 
						autoCapitalize={'none'} 
						value={username} 
						onChange={( eleName, eleValue ) => setUsername( eleValue )} 
						autoComplete='username'
						/>
					<InputPassword 
						elementName='password' 
						elementLabel='Password' 
						value={password} 
						onChange={( eleName, eleValue ) => setPassword( eleValue )} 
						autoComplete='current-password'
						/>
					
					{showMessage400 ? <>
						<div style={{color: 'tomato'}}>Incorrect username or password.</div>
					</> : null}
					
					<Button onClick={handleSubmit} disabled={!validateForm()}>Login</Button>

				</Form>
				Or <Link className='hyperlink' to='/register'>sign up</Link>
			</div>
		</>
	);

};

