

import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation, useNavigate, } from 'react-router-dom';

import { Breadcrumb, Button, } from 'antd';
import axios from 'axios';
import { API_COLLECTIONS_URL } from '../utils';
import { CHEVRON_RIGHT_COMPACT } from '../components/icons';

export default function CollectionLayout( props ) {

	// 

	const [ collection, setCollection ] = useState( {} );

	const { collectionId } = useParams();

	const navigate = useNavigate();

	const location = useLocation();
	const pathSnippets = location.pathname.split( '/' ).filter((i) => i);
	const pagePath = pathSnippets[ pathSnippets.indexOf( collectionId ) + 1 ];



	useEffect( () => {
		if ( collectionId ) {
			axios.get( API_COLLECTIONS_URL + '/' + collectionId, {
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-Type': 'application/json',
				}
			} )
				.then( data => {
					if ( data.status !== 200 ) {
						return;
					}
					
					// validate 'elements'
					if ( ! data.data.hasOwnProperty( 'definition' ) ) data.data.definition = {};
					if ( ! data.data.definition.hasOwnProperty( 'elements' ) ) data.data.definition.elements = [];
					
					setCollection( data.data );

				} )
				.catch( error => {
					if ( error.response.status === 401 ) {
						navigate( '/login?isRedirect=true' );
					}
					else {
						console.log( JSON.stringify( error, null, '\t' ) );
					}
				} );
		}
	}, [ collectionId, navigate ] );

	// root element
	const bcItems = [
		{
			title: <Button type='link' style={{fontSize:'1.25em'}} onClick={() => navigate( '/collections' )}>All collections</Button>,
			// title: <Link to={'/collections'}>All collections</Link>,
			key: 'collections'
		},
	];

	// has collectionId 
	if ( collectionId ) {
		bcItems.push( {
			title: <Button type='link' style={{fontSize:'1.25em'}} onClick={() => navigate( '/collections/' + collectionId + '/events' )}>{collection?.name}</Button>,
			key: 'collection'
		} );

		const pageName = () => {
			if ( pagePath === 'events' )
				return 'Events';
			if ( pagePath === 'form' )
				return 'Settings';
			return null;
		}

		if ( pageName() ) {
			bcItems.push( {
				title: <Button type='text' disabled={true} style={{fontSize:'1.25em'}}>{pageName()}</Button>,
				key: 'pageName'
			} );
		}
	}

	const Separator = () => {


		return ( <>
			<span style={{fontSize: '1.5em'}}>
				<CHEVRON_RIGHT_COMPACT />
			</span>
		</> );
	};

	return ( <>

		{! bcItems ? <Breadcrumb items={bcItems} separator={<Separator />} /> : null}
		
		<Outlet />
		
	</> );

}