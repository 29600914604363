

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import { Form, Button, App, Modal, } from 'antd';

import axios from 'axios';

import { API_COLLECTIONS_URL, API_EVENTS_URL, } from '../../utils';
import { AdminJSON } from '../../utils/admin';
import EventFormElement from './EventFormElement';
import { CLOSE } from '../icons';
import { TagsInput } from '../Tags';



export default function EventForm( { eventId, collectionId } ) {

	const isNewRecord = eventId + '' === '-1';

	//
	const [ event, setEvent ] = useState({
		collection_id: '',
		title: '',
		// createdBy: '',
		// createdOn: '',
		elements: {},
		tags: [],
	});

	const [ collection, setCollection ] = useState({
		name: '',
		definition: {
			elements: []
		}
	});

	const [ triggerRefreshCollection, setTriggerRefreshCollection ] = useState( false );

	const navigate = useNavigate();

	const { message } = App.useApp();

	const { confirm } = Modal;



	// GET EVENT
	useEffect( () => {
		if ( ! isNewRecord ) {
			axios.get( API_EVENTS_URL + '/' + eventId, {
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-Type': 'application/json'
				}
			} ).then( data => {
					if ( data.status !== 200 ) {
						return false;
					}

					setEvent( data.data );
			});
		}

	}, [ eventId, isNewRecord, ] );
	
	// GET COLLECTION
	useEffect( () => {
		
		let id;

		if ( event.collection_id ) {
			id = event.collection_id;
		}
		else if ( collectionId ) {
			id = collectionId;
		}
		if ( id ) {
			axios.get( API_COLLECTIONS_URL + '/' + id, {
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-Type': 'application/json'
				}
			} ).then( data => {
				if ( data.status !== 200 ) {
					return false;
				}

				setCollection( data.data );

				setTriggerRefreshCollection( false );

				// add collection to event
				if ( event.collection_id === '' ) {
					setEvent( prevState => ( { ...prevState, collection_id: data.data._id } ) );
				}

				// fill in missing elements
				data.data.definition.elements.forEach( element => {
					if ( event.elements && ! event.elements.hasOwnProperty( element.name ) ) {
						event.elements[ element.name ] = '';
						setEvent( prevState => ( { ...prevState, elements: { ...prevState.elements, [ element.name ]: '' } } ) )
					}
				} );
			});
		}
	}, [ collectionId, event.collection_id, event.elements, isNewRecord, triggerRefreshCollection ] );



	const handleSubmit = ( e ) => {

		if ( ! isNewRecord ) {
			// update existing
			console.log( event );
			axios.put( API_EVENTS_URL + '/' + event._id,
				event,
				{
					headers: {
						Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
						'Content-Type': 'application/json'
					}
				} ).then( data => {
					if ( data.status !== 200 ) {
						return;
					}
					
					message.success( 'Record saved.' );

					navigate( '/collections/' + collectionId + '/events' );
				} ).catch( error => {
					message.error( 'Something went wrong. The record may not have saved.' );
				} );
		}
		else {
			// insert new
			axios.post( API_EVENTS_URL,
			event,
			{
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-Type': 'application/json'
				}
			} ).then( data => {
				if ( data.status !== 200 ) {
					return;
				}

				// const eventId = data.data.insertedId;

				message.success( 'Record saved.' );

				navigate( '/collections/' + collectionId + '/events/' + eventId );
				navigate( '/collections/' + collectionId + '/events' );
			} ).catch( error => {
				message.error( 'Something went wrong. The record may not have saved.' );
			} );
		}

	};

	const handleDeleteClick = () => {

		confirm( {
			title: 'Confirm delete',
			// icon: '',
			content: 'Click OK to delete this event.',
			onOk() {
				handleDelete();
			},
			onCancel() {},
		} );

	};

	const handleDelete = () => {
		
		axios.delete( API_EVENTS_URL + '/' + event._id,
			{
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
					'Content-Type': 'application/json'
				}
			} )
			.then( data => {
				console.log( JSON.stringify( data, null, '\t' ) );
				if ( data.status !== 200 ) {
					return;
				}

				message.success( 'Record deleted.' );

				navigate( '/collections/' + collectionId + '/events' );
			} )
			.catch( error => {
				message.error( 'Something went wrong. The record may not have been deleted.' );
			} );

	};

	// const handleChange = e => setEvent(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
	const handleFEChange = ( name, value ) => setEvent(prevState => ({ ...prevState, elements:{ ...prevState.elements, [ name ]: value } } ) );

	const handleTagsChange = tags => setEvent( prevState => ( { ...prevState, tags: tags } ) );



	return (<>

		<h3>Edit event</h3>
		<Link to={'/collections/' + collectionId + '/events'}>Back to collection</Link><br />
		<Form>

			{collection.definition.elements.map( element => <EventFormElement
				key={element.name} 
				event={event} 
				element={element} 
				value={event.elements[element.name]} 
				onChange={handleFEChange} 
				/>)}

			<TagsInput 
				value={event.tags} 
				onChange={handleTagsChange} 
				tagList={collection.definition?.tags}
				collectionId={collectionId}
				refreshCollection={() => setTriggerRefreshCollection( true )}
			/>

			{collection?.allowAttachments ? <>
				<div>
					<h5>Attachments</h5>
					
				</div>
			</> : <></>}

			<Button onClick={handleSubmit}>
				Save
			</Button>

			{isNewRecord ? null : <Button className='mx-1' danger={true} onClick={handleDeleteClick} title='Delete'>
				<CLOSE />
			</Button>}

		</Form>



		<AdminJSON obj={event} />

	</>);

};