

// 

import dayjs from 'dayjs';
import { AdminJSON } from '../utils/admin';
import { Steps, } from 'antd';
import { CLOCK, DOCUMENT,  } from './icons';
import { sToDurationString } from '../utils/date';
import { TagsDisplay } from './Tags';
import EventElement from './EventElement';
import { InputSelect } from './Input';
import { useState } from 'react';
import { Link } from 'react-router-dom';



export default function Timeline ( props ) {

	// 

	const { 
		events = [], 
		defElements = [], 
		sortBy,
	} = props;

	const [ timeBetweenThreshold, setTimeBetweenThreshold ] = useState( 15 * 60 );



	const timelineEvents = [];

	const buildEventDescription = ( e ) => {
		//

		return <>
			{/* <div className=''>
				{defElements.map( de => <div key={de.name}>{de.label.singular}: {e.elements[ de.name ]}</div> ) }
			</div> */}
			<div className='border rounded'>
				{defElements.map( de => <EventElement key={de.name} event={e} element={de} display='timeline' /> )}
				<TagsDisplay tagNames={e.tags} />
			</div>
		</>
	};
	
	events.forEach( ( e, idx, arr ) => { 
		//

		const eventObj = {};
		eventObj.title = <Link to={'/collections/' + e.collection_id + '/events/' + e._id}>
			{dayjs( [ 'createdOn', 'lastUpdate' ].includes( sortBy ) ? e[ sortBy ] : e.elements[ sortBy ] ).format( 'MMM DD, YYYY' )}
		</Link>
		
		eventObj.subTitle = sToDurationString( {
			value: dayjs().diff( dayjs( [ 'createdOn', 'lastUpdate' ].includes( sortBy ) ? e[ sortBy ] : e.elements[ sortBy ] ), 'second' ),
		} );
		eventObj.description = buildEventDescription( e );
		// eventObj.description = <>
		// 	<div className=''>
		// 		{defElements.map( de => <div key={de.name}>{de.label.singular}: {e.elements[ de.name ]}</div> ) }
		// 	</div>
		// 	<TagsDisplay tagNames={e.tags} />
		// </>;
		eventObj.status = 'process';
		eventObj.icon = <DOCUMENT />;

		timelineEvents.push( eventObj );


		// 
		// 'TIME BETWEEN' EVENTS
		// 
		if ( idx < ( arr.length - 1 ) ) {

			const eleA = arr[ idx ];
			const eleB = arr[ idx + 1 ];

			let timeA, timeB;

			if ( sortBy === 'createdOn' || sortBy === 'lastUpdate' ) {
				timeA = dayjs( eleA[ sortBy ] );
				timeB = dayjs( eleB[ sortBy ] );
			}
			else {
				timeA = dayjs( eleA.elements[ sortBy ] );
				timeB = dayjs( eleB.elements[ sortBy ] );
			}

			const durationBetween = timeA.diff( timeB, 'second' );

			if ( durationBetween > timeBetweenThreshold ) {
				timelineEvents.push( {
					title: '',
					// subTitle: durationBetween,
					subTitle: sToDurationString( { 
						value: durationBetween, 
						includeSuffix: false,
						preserveSign: false,
					} ),
					description: '',
					status: '',
					icon: <CLOCK />,
				} );
			}
		}
	} );

	

	return ( <>

		{/* <Steps progressDot direction='vertical'>
			{buildTimelineItems( events ).map( e => <StepItem
				title={e.title} description={e.description} />)}
		</Steps> */}


		<div className='d-flex flex-row-reverse'>
			<InputSelect 
				elementName={'timeBetweenThreshold'}
				elementLabel={`'Time between' threshold`}
				value={timeBetweenThreshold}
				onChange={( elementName, value ) => setTimeBetweenThreshold( value )}
				labelInline={true}
				allowClear={false}
				options={[
					{
						value: 0,
						label: 'Show all',
					},
					{
						value: 1 * 60,
						label: '1 minute',
					},
					{
						value: 5 * 60,
						label: '5 minutes',
					},
					{
						value: 15 * 60,
						label: '15 minutes',
					},
					{
						value: 1 * 60 * 60,
						label: '1 hour',
					},
					{
						value: 3 * 60 * 60,
						label: '3 hours',
					},
					{
						value: 6 * 60 * 60,
						label: '6 hours',
					},
					{
						value: 12 * 60 * 60,
						label: '12 hours',
					},
					{
						value: 24 * 60 * 60,
						label: '1 day',
					},
					{
						value: 7 * 24 * 60 * 60,
						label: '1 week',
					},
					{
						value: 4 * 7 * 24 * 60 * 60,
						label: '4 weeks',
					},
					{
						value: 13 * 7 * 24 * 60 * 60,
						label: '3 months',
					},
					{
						value: 26 * 7 * 24 * 60 * 60,
						label: '6 months',
					},
					{
						value: 365 * 24 * 60 * 60,
						label: '1 year',
					},
				]}
			/>
		</div>

		<Steps 
			// progressDot
			direction='vertical'
			items={timelineEvents}
			/>


		<AdminJSON obj={events} />

	</> );

};




// const buildTimelineItems = ( events ) => {
// 	// var arr = [];



// 	// const timelineEvents = events.map( e => ( {
// 	// 	title: dayjs( e.createdOn ).format( 'MMM DD, YYYY' ) + ' - ' + e.title,
// 	// 	subTitle: 'sub title',
// 	// 	description: 'foo',
// 	// 	status: 'process',
// 	// 	icon: <DOCUMENT />,
// 	// } ) );
// 	const timelineEvents = [];
	
// 	events.forEach( ( e, idx, arr ) => { 
// 		timelineEvents.push( {
// 			title: dayjs( e.createdOn ).format( 'MMM DD, YYYY' ) + ' - ' + e.title,
// 			subTitle: 'sub title',
// 			description: 'foo',
// 			status: 'process',
// 			icon: <DOCUMENT />,
// 		} );

// 		if ( idx < ( arr.length - 1 ) ) {
// 			timelineEvents.push( {
// 				title: '',
// 				subTitle: '34 minutes',
// 				description: '',
// 				status: '',
// 				icon: <CLOCK />,
// 			} );
// 		}
// 	} );


	
	
	
// 	return timelineEvents;
	
// 	// return events.map( e => <StepItem 
// 	// 	title={dayjs( e.createdOn ).format( 'MMM DD, YYYY' ) + ' - ' + e.title}
// 	// 	subTitle={''}
// 	// 	description={'foo'}
// 	// 	status='process'
// 	// 	icon={<REFRESH />}
// 	// /> );

// };

