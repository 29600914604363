

import { forwardRef, } from 'react';
// import Button from 'react-bootstrap/esm/Button';

import { dataTypes } from '../../utils/dictionary';
import ElementOptions from './ElementOptions';

import { Collapse, Tabs, Tag, Button, } from 'antd';
import { CLOSE, DRAG_HANDLE, DROPDOWN, PLUS, } from '../icons';
import { InputSelect, InputText } from '../Input';
import ChoiceList from './ChoiceList';
import { useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';



export default function CollectionFormElement( props ) {

	const { value, onChange, onDelete, idx, } = props;

	const elementName = value.name || '';

	// const [ bodyOpen, setBodyOpen ] = useState( false );



	// DND-KIT
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition
	} = useSortable( { id: props.id } );

	const dndStyle = {
		transform: CSS.Transform.toString( transform ),
		transition,
		touchAction: 'none',
	};



	const handleChange = ( name, value ) => onChange( elementName, name, value );

	const handleDelete = () => onDelete( elementName );



	const dataTypeLabel = dataTypes.find( e => e.name === value.dataType )?.label;

	const getIcon = () => {
		return dataTypes.find( e => e.name === value.dataType )?.bsIconJSX;
	};



	const handleChoiceChange = ( name, val ) => {
		// TODO

		// console.log( 'value: ', value );
		// console.log( 'name: ', name );
		// console.log( 'val: ', val );
		onChange( elementName, name, val );

	};



	useEffect( () => {
		if ( value.label.singular !== value.name ) {
			onChange( elementName, 'name', value.label.singular );
		}
	}, [ value, onChange, elementName, ] );


	//////////////////////////////////////////////////
	//   
	//   COLLAPSE
	//   
	//////////////////////////////////////////////////
	
	const cHeader = () => {

		const isTitle = value.options.isTitle;
		const isRequired = value.options.required;
		const choicesLength = value.options.choices.length;
		const dragHandleProps = {
			className: 'mx-2',
			...attributes,
			...listeners,
		};

		const DragHandle = forwardRef( function DragHandle( props, ref ) {
			return ( <span {...props} ref={ref}>
				<DRAG_HANDLE />
			</span> );
		} );

		return <>
			<div className='d-flex container-fluid ' style={{paddingRight: '1em',paddingLeft: 0,}}>
				<Tag color='#108ee9' className='' title={dataTypeLabel}>
					{/* TODO - return empty icon */}
					{getIcon() || <PLUS />}
				</Tag>
				<span className='flex-grow-1'>
					{value.label.singular}
				</span>
				{isTitle ? <Tag color='blue' className='' title='The title element for events'>Title</Tag> : null}
				{isRequired ? <Tag color='volcano' className='' title='This element must be populated on all events'>Req'd</Tag> : null}
				{choicesLength ? <Tag color='volcano' className='' title='Choices are defined for this element'>
					<DROPDOWN />
					{choicesLength}
				</Tag> : null}

				<DragHandle {...dragHandleProps} ref={setActivatorNodeRef} /> 

			</div>
		</>;
	};

	const cChildren = () => {


		const tItems = [];
		tItems.push( {
			key: 1,
			label: 'Settings',
			children: <>
				{/* <InputText elementName='name' elementLabel='Name' value={value.name} onChange={handleChange} required={true} /> */}
				<InputSelect elementName='dataType' elementLabel='Data type' value={value.dataType} onChange={handleChange} options={dataTypes.map( e => { return {value: e.name, label:<>{e.bsIconJSX}{' ' + e.label}</>} } )} required={true} />
				<InputText elementName='labelSingular' elementLabel='Label (singular)' value={value.label.singular} onChange={handleChange} required={true} />
				<InputText elementName='labelPlural' elementLabel='Label (plural)' value={value.label.plural} onChange={handleChange} required={true} />

				<div className='d-flex'>
					<Button className='flex-grow-1 flex-md-grow-0' danger={true} onClick={handleDelete}>
						<CLOSE /> Remove element
					</Button>
				</div>
			</>
		} );
		
		tItems.push( {
			key: 2,
			label: 'Advanced',
			children: <>
				<ElementOptions options={value.options} onChange={handleChange} elementDefName={elementName} elementValue={value} />
			</>
		} );

		tItems.push( {
			key: 3,
			label: 'Choices',
			children: <>
				{/* Update onChange function */}
				<ChoiceList choices={value.options.choices} onChange={handleChoiceChange} /> 

				{/* <InputSwitch 
					elementName={'selectIcons'}
					elementLabel={'Use icons'}
					value={value.options?.selectIcons}
					onChange={handleChange}
				/> */}
			</>
		} );

		

		return <>
			<Tabs
				defaultActiveKey='1'
				centered
				items={tItems}
				/>


			{/* <InputText elementName='name' elementLabel='Name' value={value.name} onChange={handleChange} required={true} />
			<InputSelect elementName='dataType' elementLabel='Data type' value={value.dataType} onChange={handleChange} options={dataTypes.map( e => { return {value: e.name, label:<>{e.bsIconJSX}{' ' + e.label}</>} } )} required={true} />
			<InputText elementName='labelSingular' elementLabel='Label (singular)' value={value.label.singular} onChange={handleChange} required={true} />
			<InputText elementName='labelPlural' elementLabel='Label (plural)' value={value.label.plural} onChange={handleChange} required={true} />

			<ElementOptions options={value.options} onChange={handleChange} elementDefName={elementName} />

			<Button variant='danger' onClick={handleDelete}>
				Remove element
			</Button> */}
		</>;
	};

	const cItems = [
		{
			key: idx,
			label: cHeader(),
			children: cChildren(),
		},
	];



	//////////////////////////////////////////////////
	//   
	//   ELEMENT OPTIONS
	//   
	//////////////////////////////////////////////////
	


	return ( <>

		<div 
			ref={setNodeRef} 
			style={dndStyle} 
		>
			
			<Collapse
				collapsible='header'
				items={cItems}
				className='m-2'
				/>

		</div>

	</> );

};