

import { AiFillSetting, AiFillStar, AiOutlineMenu, AiOutlinePlus, AiOutlineQuestionCircle, AiOutlineSearch, AiOutlineStar } from 'react-icons/ai';
import { BiText } from 'react-icons/bi';
import { Bs123, BsCalendarDate, BsChevronDown, BsChevronUp, BsTextWrap, BsClock, BsLink45Deg, BsChevronCompactRight, BsCheckCircle, BsCheckCircleFill, BsBan, BsPersonFill, } from 'react-icons/bs';
import { MdClose, MdDirectionsCar, MdFilterDrama, MdOutlineBoy, MdOutlineGirl, MdOutlineHome, MdOutlineSchool, MdSportsFootball, MdKeyboardBackspace, MdAdminPanelSettings, } from 'react-icons/md';
import { TbDog, TbSelect, TbUfo, TbCalendarTime, TbSortAscendingLetters, TbSortDescendingLetters, } from 'react-icons/tb';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { LuRefreshCw, } from 'react-icons/lu';
import { IoDocumentOutline, } from 'react-icons/io5';
import { VscBlank, } from 'react-icons/vsc';



// CHEVRONS
export const CHEVRON_UP = BsChevronUp;
export const CHEVRON_DOWN = BsChevronDown;
export const CHEVRON_RIGHT_COMPACT = BsChevronCompactRight;



// SYMBOLS
export const PLUS = AiOutlinePlus; // plus icon - used for 'create new' buttons
export const CLOSE = MdClose; // X - used to indicate delete or close
export const BACK_ARROW = MdKeyboardBackspace; // Left arrow used to indicate navigating back
export const SETTINGS_COG = AiFillSetting;

export const CHECK_CIRCLE_OUTLINE = BsCheckCircle;
export const CHECK_CIRCLE_FILL = BsCheckCircleFill;
export const BAN_CIRCLE = BsBan;

export const RATING_STAR_OUTLINE = AiOutlineStar;
export const RATING_STAR_FILL = AiFillStar;

export const ADMIN_BADGE = MdAdminPanelSettings;



// UI / NAV
export const PERSON = BsPersonFill; // generic person for user avatar
export const MENU_OUTLINE = AiOutlineMenu;
export const DRAG_HANDLE = RxDragHandleDots2;
export const HELP_QUESTION_MARK = AiOutlineQuestionCircle;
export const MAGNIFYING_GLASS = AiOutlineSearch;
export const REFRESH = LuRefreshCw;
export const SORT_ASCENDING = TbSortAscendingLetters;
export const SORT_DESCENDING = TbSortDescendingLetters;
export const DOCUMENT = IoDocumentOutline;
export const BLANK_ICON = VscBlank;



// DICTIONARY ICONS
export const CALENDAR = BsCalendarDate;
export const CALENDAR_CLOCK = TbCalendarTime;
export const CLOCK = BsClock;
export const NUM_123 = Bs123;
export const TEXT = BiText;
export const TEXT_MULTI = BsTextWrap;
export const URL_ICON = BsLink45Deg;
export const RATING_STAR = RATING_STAR_OUTLINE;

export const DROPDOWN = TbSelect; // badge used in Collapse header for collection elements



// USER SELECTABLE
export const GIRL = MdOutlineGirl;
export const BOY = MdOutlineBoy;
export const SCHOOL = MdOutlineSchool;
export const FOOTBALL = MdSportsFootball;
export const HOME = MdOutlineHome;
export const CAR = MdDirectionsCar;
export const FILTER = MdFilterDrama;
export const UFO = TbUfo;
export const DOG = TbDog;








