

import { useNavigate } from 'react-router-dom';
// import { getTokenExpiry } from '../utils';

import { isTokenValid } from '../utils';
import { useEffect } from 'react';
import { LoginWidget } from './Login';



export default function Home() {

	const navigate = useNavigate();



	useEffect( () => {
		if ( isTokenValid() ) {
			navigate( '/collections' );
		}
	}, [ navigate, ] );



	return <>
	
		{/* <h1>Welcome to Keepr</h1> */}
		<h2>Keepr remembers, so you don't have to</h2>

		<LoginWidget />

		
	
	</>;

}