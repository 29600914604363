
// REACT
import { useState, useEffect, } from 'react';
import { Outlet, Link, useLocation, useNavigate, } from 'react-router-dom';

// INTERNAL
import HeaderDetail from '../components/HeaderDetail';
import { userIsAdmin } from '../utils';

// ICONS

import { Button, Popover, } from 'antd';
import { MENU_OUTLINE } from '../components/icons';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WHITE } from '../utils/colors';
// import { Helmet } from 'react-helmet';



export default function Layout( { keeprLogo } ) {

	//


	const [ navPopoverOpen, setNavPopoverOpen ] = useState( false );

	const { pathname } = useLocation();

	const navigate = useNavigate();

	useEffect( () => {
		setNavPopoverOpen( false );
	}, [ pathname ] );



	// validate token
	// TODO - remove for a better auth state strategy . . .
	const token = window.localStorage.getItem( 'token' );
	
	if ( ! token || token === 'undefined' || token === 'null' ) {
		window.localStorage.removeItem( 'token' );
	}



	const MobileNavContent = () => {

		//

		const navItems = [
			{
				label: 'Home',
				url: '/',
			},
			{
				label: 'Collections',
				url: '/collections',
			},
			{
				label: 'Login',
				url: '/login',
			},
			{
				label: 'Logout',
				url: '/logout',
			},
		];

		if ( userIsAdmin ) {
			navItems.push( {
				label: 'Temp',
				url: '/temp',
			} );
		}



		return <>
			<ul style={{listStyleType: 'none'}}>
				{navItems.map( item => <li key={item.label}>
					<Link 
						to={item.url}
						className='d-block rounded mx-1 my-2 px-2 py-1'
						style={{fontSize: '2em', minWidth: '300px', backgroundColor: '#ddd', border: '2px solid #bbb'}}
					>
						{item.label}
					</Link>
				</li> )}
			</ul>
		</>;

	};



	return ( <>

		{/* <Helmet>
			<meta name='viewport' content='maximum-scale=1' />
		</Helmet> */}

		<header 
			id='header' 
			className='d-flex align-items-center' 
			style={{
				backgroundColor: COLOR_PRIMARY,
				boxShadow: '0 5px 5px #999',
			}}
		>
			
			<span
				className='m-1'
				style={{flexGrow: 1}}
			>
				<Link 
					className=''
					to={'/'}
				>
					<img 
						src='/img/keepr-logo-transparent-bg.png' 
						alt='keepr logo' 
						style={{height: '4em'}}
					/>
				</Link>
			</span>
			
			<HeaderDetail className='header-detail'/>

			<Popover
				content={<MobileNavContent />}
				title={null}
				trigger='click'
				placement='bottomRight'
				open={navPopoverOpen}
				onOpenChange={( newOpen ) => setNavPopoverOpen( newOpen )}
				>
				<Button
					className='d-inline d-md-none p-1 m-1'
					style={{height: 'auto', backgroundColor: COLOR_SECONDARY, color: COLOR_WHITE,}}
					ghost
					>
					<MENU_OUTLINE className='big-icon' />
				</Button>
			</Popover>

		</header>



		{/* desktop nav */}
		<nav className='desktop d-none d-md-block'>
			<ul>
				<li>
					<Button type='link' onClick={() => navigate( '/' )}>Home</Button>
				</li>
				<li>
					<Button type='link' onClick={() => navigate( '/collections' )}>Collections</Button>
				</li>
				<li>
					<Button type='link' onClick={() => {}}>Templates</Button>
				</li>
				{userIsAdmin ? <li>
					<Button type='link' onClick={() => navigate( '/temp' )}>Temp</Button>
				</li> : null}
				{/* <li>
					<Button type='link' onClick={() => navigate( '/login' )}>Login</Button>
				</li>
				<li>
					<Button type='link' onClick={() => navigate( '/logout' )}>Logout</Button>
				</li> */}
			</ul>
		</nav>
		<div className='p-2'>
			<Outlet />
		</div>

		<footer id='footer'>
			<span id='footer-content'>KEEPR &copy; 2023. All rights reserved.</span>
		</footer>
	</>	);

}
