

import React, { useState, useEffect, } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Container, Row, Col, } from 'react-bootstrap';

// import UserString from '../users/UserString';
// import { dataTypes } from '../../utils/dictionary';
import { Button, } from 'react-bootstrap';


// import { AiFillSetting,  } from 'react-icons/ai';
// import { BsChevronDown, BsChevronUp, BsPlusLg } from 'react-icons/bs';
import { PLUS, RATING_STAR_FILL, RATING_STAR_OUTLINE, SETTINGS_COG } from '../icons';
import _ from 'lodash';
import axios from 'axios';
import { API_COLLECTIONS_URL } from '../../utils';
import { LoadingList } from '../LoadingPlaceholder';


export default function CollectionList( props ) {
	
	const collections = props.data;

	const showPlaceholder = props.showPlaceholder;

	return ( <>
		<Container fluid>
			<Row xs={1} md={2} xl={3}>

				{showPlaceholder ? 
					<LoadingList /> :
					collections.map( collection => <Col key={collection._id}>
						<CollectionListItem className='' style={{ minWidth: '290px', }} collection={collection} />
					</Col> )
				}
				
			</Row>
		</Container>

	</> );

};



const CollectionListItem = ( props ) => {

	const { collection } = props;

	

	const [ isFavorite, setIsFavorite ] = useState( collection?.layoutTags?.indexOf( 'favorite' ) > -1 );

	const navigate = useNavigate();



	useEffect( () => {
		const colObj = _.merge( {}, collection );

		if ( ! colObj?.layoutTags ) colObj.layoutTags = [];

		let allowUpdate = false;

		if ( isFavorite === true ) {
			// cehck for 'favorite' in layoutTags
			if ( ! colObj?.layoutTags?.indexOf( 'favorite' ) > -1 ) {
				colObj.layoutTags.push( 'favorite' );
				allowUpdate = true;
			}
		}
		else {
			// remove favorite
			colObj.layoutTags = colObj.layoutTags.filter( item => item !== 'favorite' );
			allowUpdate = true;
		}

		if ( allowUpdate ) {
			axios.put( API_COLLECTIONS_URL + '/' + collection._id, 
			colObj, 
			{
				headers: {
					Authorization: 'Bearer ' + window.localStorage.getItem( 'token' ),
				}
			} ).then( data => {
				// refresh page or otherwise refetch collections to dynamically update favorites
			} );
		}
	}, [ isFavorite, collection, ] );



	if ( ! collection.hasOwnProperty( 'definition' ) ) {
		collection.definition = {};
	}

	if ( ! collection.definition.hasOwnProperty( 'elements' ) ) {
		collection.definition.elements = [];
	}

	if ( ! collection.hasOwnProperty( 'sharedWith' ) ) {
		collection.sharedWith = [];
	}
	else if ( typeof collection.sharedWith === 'string' ) {
		collection.sharedWith = ( collection.sharedWith + '' ).split( ',' );
	}

	

	return (
		<>
			<div className='rounded p-1 m-1' style={{borderColor: collection?.color, borderStyle: 'solid', borderWidth: '3px',}}>

				<div className='list-item-title d-flex'>

					<Link className='d-inline-block flex-grow-1 text-decoration-none' to={'/collections/' + collection._id + '/events'}>
						<span style={{fontSize: '1.5em', color: 'black', }}>{collection.name}</span>
					</Link>

					<Button className='p-1 m-1' onClick={() => navigate( '/collections/' + collection._id + '/events/-1' )}>
						<PLUS className='medium-icon' title='New item' />
					</Button>

					<Button className='p-1 m-1' onClick={() => navigate( '/collections/' + collection._id + '/form' )}>
						<SETTINGS_COG className='medium-icon' title='Collection settings' />
					</Button>

					<Button className='p-1 m-1' onClick={() => setIsFavorite( ! isFavorite )}>
						{isFavorite ? <RATING_STAR_FILL className='medium-icon' title='Unfavorite' /> : <RATING_STAR_OUTLINE className='medium-icon' title='Favorite' />}
					</Button>

					{/* <Button className='p-1 m-1' onClick={() => setOpen( ! open )}>
						{open ? <BsChevronUp className='medium-icon' /> : <BsChevronDown className='medium-icon' />}
					</Button> */}

				</div>

				{/* <Collapse in={open}>
					<div className='list-item-detail'>
						{collection.detail ? <><span className='component-detail'>{collection.detail}</span><br /></> : null}
						
						<span className='component-attr'><strong>Owned by:</strong> <UserString userId={collection.ownedBy} type='fullName' /></span><br />
						
						{collection.sharedWith.length ? <><span className='component-attr'><strong>Shared with:</strong> {collection.sharedWith.map( ( e, i ) => <React.Fragment key={e}>
							{i === 0 ? null : ', '}<UserString userId={e} type='fullName' /></React.Fragment>)}</span><br /></> : null}

						<span className='component-attr'><strong>Elements:</strong></span>
							
						{collection.definition.elements.map( element => <span 
							className='d-block'
							key={element.name}
							style={{border:'solid black 1px',margin:'2px',padding:'2px 5px',borderRadius:'4px',}}>
							{element.label.singular} {'['}
							{element.options?.choices?.length ? 'Choice list' : dataTypes.find( e => e.name === element.dataType )?.label}
							{']'}
						</span> )}
					</div>
				</Collapse> */}
								
			</div>
		</>
	);

};