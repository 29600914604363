

//

import { Button } from "antd";
import { InputSelect } from "./Input";
import { SORT_ASCENDING, SORT_DESCENDING } from "./icons";



export default function SortEvents ( props ) {
	//
	const {
		sortBy,
		setSortBy,
		sortOptions,
		sortAscending,
		setSortAscending,
	} = props;



	return ( <>
		<div className='d-flex'>
			<span style={{flexGrow: 1}}></span>
			<InputSelect 
				elementName='sortBy'
				elementLabel='Sort by'
				labelInline={true}
				value={sortBy}
				onChange={( elementName, value ) => setSortBy( value )}
				allowClear={false}
				options={sortOptions}
				// options={[ 
				// 	...collection.definition.elements.filter( e => e.dataType === 'date' ).map( e => ( { value: e.name, label: e.label.singular } ) ),
				// 	{ value: 'createdOn', label: 'Created on' },
				// 	{ value: 'lastUpdate', label: 'Updated on' },
				// ]}
			/>
			<Button className='p-1 m-1' onClick={() => setSortAscending( ! sortAscending )}>
				{ sortAscending ? 
					<SORT_ASCENDING className='medium-icon' title='Ascending' /> :
					<SORT_DESCENDING className='medium-icon' title='Descending' />
				}
			</Button>
		</div>
	</> );
}