


import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { App as AntdApp } from 'antd';

import Layout from './pages/Layout';
import Home from './pages/Home';
import Page404 from './pages/Page404';
import Collections from './pages/Collections';
// import Events from './pages/DELETE_Events';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Register from './pages/Register';
import Logout from './pages/Logout';
// import CreateCollection from './pages/CreateCollection';
import ViewCollection from './pages/ViewCollection';
import ViewCollectionEvents from './pages/ViewCollectionEvents';
// import ViewCollections from './pages/ViewCollections';
import CollectionsLayout from './pages/CollectionsLayout';
import ViewEvent from './pages/ViewEvent';
import Temp from './Temp';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import 'bootstrap/dist/css/bootstrap.min.css';

import './css/main.css';
import './css/components.css';
import AccountChangePassword from './pages/AccountChangePassword';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AntdApp>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={ <Layout /> }>
            <Route index element={ <Home /> } />
            <Route path='*' element={ <Page404 /> } />
            <Route path='collections/' element={ <CollectionsLayout /> }>
            {/* <Route path='collections/'> */}
              <Route index element={ <Collections /> } />
              {/* <Route path='createCollection' element={ <CreateCollection /> } /> */}
              <Route exact path='viewCollection/:collectionId' element={ <ViewCollection /> } />
              <Route exact path=':collectionId/form' element={ <ViewCollection /> } />
              <Route exact path=':collectionId/events' element={ <ViewCollectionEvents /> } />
              {/* <Route path='viewCollections' element={ <ViewCollections /> } /> */}
              <Route exact path=':collectionId/events/:eventId' element={ <ViewEvent /> } />
            </Route>
            {/* <Route path='events' element={ <Events /> } /> */}
            {/* <Route exact path='events/view?/:id?' element={ <ViewEvent /> } /> */}
            <Route path='profile' element={ <Profile /> } />

            <Route path='account/' >
              <Route index element={ <Profile /> } />
              <Route path='change-password' element={ <AccountChangePassword />} />
            </Route>
            <Route path='account' element={ <Profile /> } />
            <Route path='login' element={ <Login /> } />
            <Route path='register' element={ <Register /> } />
            <Route path='logout' element={ <Logout /> } />

            <Route path='temp' element={ <Temp />} />
            
          </Route>
        </Routes>
      </BrowserRouter>
    </AntdApp>
  </React.StrictMode>
);



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBp6ai2x7nzo_rhPMxqLbb60PGYAIGciYY",
  authDomain: "keepr-web.firebaseapp.com",
  projectId: "keepr-web",
  storageBucket: "keepr-web.appspot.com",
  messagingSenderId: "776918337200",
  appId: "1:776918337200:web:b8af8b207932fd5b2bdd75",
  measurementId: "G-K5FK5J0DD6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log( analytics );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
